<template>
  <v-expansion-panel class="shadow br-20 mb-5">
    <v-expansion-panel-header>
      <div>
        <div class="mb-4">
          <b class="h4 ">{{ label }}</b>
        </div>
        <div>
          <v-chip class="me-3 shadow" color="success">Всего:
            {{ result.summi.summaVsego.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }} руб.
          </v-chip>
          <v-chip class="me-3 shadow text-white" color="blue">Материалы:
            {{ result.summi.summaMaterialov.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}
            руб.
          </v-chip>
          <v-chip class="me-3 shadow" color="warning">Работы:
            {{ result.summi.summaRabot.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }} руб.
          </v-chip>
        </div>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content>

      <!-- переменные -->
      <hr class="mt-2">
      <h5 class="mb-3">Переменные</h5>
      <div class="row">

        <div v-for="item in paramsData.variable" :key="item.name" class="col-12 col-md-4">
          <v-select
              v-if="item.items"
              class="mb-2"
              :label="label"
              item-text="priceLabel"
              item-value="id"
              v-model="data[item.name]"
              :items="item.items"
          />

          <v-text-field
              v-else
              type="number"
               step="any"
              class="mb-2"
              :label=item.label
              v-model="data[item.name]"
          />
        </div>

      </div>

      <!-- площадь, периметр и объем -->
      <div v-if="result.vichisliaemieVelechini.length > 0">
        <h5 class="my-3">Вычисляемые величины</h5>
        <div class="row">

          <div class="col-12 col-md-4" v-for="item in result.vichisliaemieVelechini" :key="item.label">
            <v-text-field
                class="mb-2"
                :label="item.label"
                disabled
                :value="item.value.toLocaleString('ru-RU',{'maximumFractionDigits':2})"
            />
          </div>


        </div>
      </div>

      <!-- работы -->
      <div v-if="result.summi.summaRabot > 0">
        <h5 class="my-3">Работы</h5>
        <div class="table-responsive">
          <table class="table table-bordered align-middle text-center small ">
            <tbody>
            <!-- шапка -->
            <tr class="font-weight-bold">
              <td>Название</td>
              <td>Рабочие/машины</td>
              <td>Ед. измерения</td>
              <td>Цена, руб</td>
              <td>Количество</td>
              <td>Итого, руб</td>
            </tr>

            <tr v-for="item in result.jobs" :key="item.label">
              <td>{{ item.title }}</td>
              <td>{{ item.value }}</td>
              <td>{{ item.edenizaIzmerenia }}</td>
              <td>{{ item.price }}</td>
              <td>{{ item.kolvo.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</td>
              <td>{{ item.sum.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}
                <formula-helper class="ms-2" :text="item.formulaLabel"/>
              </td>
            </tr>

            <tr class="font-weight-bold ">
              <td colspan="5" class="text-right">ИТОГО:</td>
              <td>{{ result.summi.summaRabot.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</td>
            </tr>


            </tbody>
          </table>
        </div>
      </div>

      <!-- материалы -->
      <div v-if="result.summi.summaMaterialov > 0">
        <h5 class="my-3">Материалы</h5>
        <div class="table-responsive">
          <table class="table table-bordered align-middle text-center small ">
            <tbody>
            <!-- шапка -->
            <tr class="font-weight-bold">
              <td>Название</td>
              <td>Материал</td>
              <td>Ед. измерения</td>
              <td>Цена, руб</td>
              <td>Количество</td>
              <td>Итого, руб</td>
            </tr>

            <tr v-for="item in result.material" :key="item.label">
              <td>{{ item.title }}</td>
              <td>{{ item.value }}</td>
              <td>{{ item.edenizaIzmerenia }}</td>
              <td>{{ item.price }}</td>
              <td>{{ item.kolvo.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</td>
              <td>{{ item.sum.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}
                <formula-helper class="ms-2" :text="item.formulaLabel"/>
              </td>
            </tr>

            <tr class="font-weight-bold ">
              <td colspan="5" class="text-right">ИТОГО:</td>
              <td>{{ result.summi.summaMaterialov.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</td>
            </tr>


            </tbody>
          </table>
        </div>
      </div>

      <div v-if="result.summi.summaVsego > 0">
        <div class="h4"><b>Итого: {{ result.summi.summaVsego.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}
          руб.</b></div>
      </div>

    </v-expansion-panel-content>

  </v-expansion-panel>
</template>

<script>
import FormulaHelper from "@/components/include/FormulaHelper";
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  name: "EstimatePanel",
  components: {FormulaHelper},

  props: ['paramsData', 'label', 'slug'],

  data: () => ({
    data: {
      kladkaSten: 1,
      razrabotkaGrunta: 1,
    },
    result: {},
    isLoad: false,
  }),

  methods: {
    async calculate() {
      this.isLoad = true

      try {
        const url = `${LOCAL_CONFIG.urls.calculationEstimateSetParamsApi}/${this.slug}`;
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(this.data),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });
        const responseData = await response.json();
        this.result = responseData.result;

      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }
      this.isLoad = false;
      this.$emit('recalc')

    },
  },

  watch: {
    data: {
      handler() {
        this.calculate();
      },
      deep: true
    },
  },

  async created() {
    this.result = this.paramsData.result;
  },

}
</script>

<style scoped>

</style>