<template>
  <div>
    <v-alert
        icon="$info"
        color="amber lighten-4"
        variant="tonal"
        :elevation="2"
        class="mb-7 w-100 small py-3 br-20"
    >Суммы по смете буду считаться автоматом из калькулятора. Пока просто разбили по видам для внесения руками
    </v-alert>


    <div class="row">

      <div class="col-12 col-md-6 mb-3">
        <label class="small">Фундамент</label>
        <v-text-field
            type="number"
                :readonly="zapretNaRedaktirovanie"
             step="any"
            class="mb-2 pt-0"
            label=""
            v-model="data.estimateFundamentPrice"
        />
      </div>

      <div class="col-12 col-md-6 mb-3">
        <label class="small">Коробка</label>
        <v-text-field
            type="number"
                :readonly="zapretNaRedaktirovanie"
             step="any"
            class="mb-2 pt-0"
            label=""
            v-model="data.estimateKorobkaPrice"
        />
      </div>

      <div class="col-12 col-md-6 mb-3">
        <label class="small">Перекрытия</label>
        <v-text-field
            type="number"
                :readonly="zapretNaRedaktirovanie"
             step="any"
            class="mb-2 pt-0"
            label=""
            v-model="data.estimatePerekritiePrice"
        />
      </div>

      <div class="col-12 col-md-6 mb-3">
        <label class="small">Армопояс</label>
        <v-text-field
            type="number"
             step="any"
                :readonly="zapretNaRedaktirovanie"
            class="mb-2 pt-0"
            label=""
            v-model="data.estimateArmpoiasPrice"
        />
      </div>

      <div class="col-12 col-md-6 mb-3">
        <label class="small">Стяжка</label>
        <v-text-field
            type="number"
                :readonly="zapretNaRedaktirovanie"
             step="any"
            class="mb-2 pt-0"
            label=""
            v-model="data.estimateStiazkaPrice"
        />
      </div>

      <div class="col-12 col-md-6 mb-3">
        <label class="small">Крыша</label>
        <v-text-field
            type="number"
                :readonly="zapretNaRedaktirovanie"
             step="any"
            class="mb-2 pt-0"
            label=""
            v-model="data.estimateKrishaPrice"
        />
      </div>

      <div class="col-12 col-md-6 mb-3">
        <label class="small">Забор</label>
        <v-text-field
            type="number"
                :readonly="zapretNaRedaktirovanie"
             step="any"
            class="mb-2 pt-0"
            label=""
            v-model="data.estimateZaborPrice"
        />
      </div>

      <div class="col-12 col-md-6 mb-3">
        <label class="small">Септик</label>
        <v-text-field
            type="number"
                :readonly="zapretNaRedaktirovanie"
             step="any"
            class="mb-2 pt-0"
            label=""
            v-model="data.estimateSepticPrice"
        />
      </div>

      <div class="col-12 col-md-6 mb-3">
        <label class="small">Окна, двери, ворота</label>
        <v-text-field
            type="number"
                :readonly="zapretNaRedaktirovanie"
             step="any"
            class="mb-2 pt-0"
            label=""
            v-model="data.estimateOknaDveriVorotaPrice"
        />
      </div>

      <div class="col-12 col-md-6 mb-3">
        <label class="small">Электричество</label>
        <v-text-field
            type="number"
             step="any"
                :readonly="zapretNaRedaktirovanie"
            class="mb-2 pt-0"
            label=""
            v-model="data.estimateElektrichestvoPrice"
        />
      </div>

      <div class="col-12 col-md-6 mb-3">
        <label class="small">Отопление, водоснабжение, канализация</label>
        <v-text-field
            type="number"
             step="any"
                :readonly="zapretNaRedaktirovanie"
            class="mb-2 pt-0"
            label=""
            v-model="data.estimateOtoplenieVodaKnsPrice"
        />
      </div>

      <div class="col-12 col-md-6 mb-3">
        <label class="small">Печь, камин</label>
        <v-text-field
            type="number"
                :readonly="zapretNaRedaktirovanie"
             step="any"
            class="mb-2 pt-0"
            label=""
            v-model="data.estimatePechIKaminPrice"
        />
      </div>


      <div class="col-12 col-md-8 mb-3">
        <v-chip class="me-3 shadow" color="success">Итого:
          {{ estimateSumPrice.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }} руб.
        </v-chip>
      </div>

      <div class="col-12 col-md-4 mb-3 text-right">
        <button v-if="!zapretNaRedaktirovanie" class="btn btn-warning shadow-sm br-20 px-5 " :disabled="isLoad" @click="saveData">
          Сохранить
        </button>

      </div>

    </div>


  </div>
</template>

<script>


import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  props: ['params', 'referenceTables', 'houseId', 'zapretNaRedaktirovanie'],


  data() {
    return {
      isLoad: false,
      data: {},
    }
  },

  methods: {
    async saveData() {
      this.isLoad = true

      try {
        let data = this.data;
        data['priceEstimate'] = this.estimateSumPrice

        Object.keys(data).forEach(function (key) {
          if (data[key] === '') {
            data[key] = 0;
          }
        })

        const url = `${LOCAL_CONFIG.urls.houseEtimate}/${this.houseId}`;
        const response = await fetch(url, {
          method: 'post',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });
        if (response.status === 200 || response.status === 201) {
          this.$emit('showAlert', 'Данные сохранены');
          this.$emit('updateData',);
        } else if (response.status === 400) {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка. Проверьте пожалуйста что все поля заполнены'});
        } else {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка. подробности в консоли браузера'});
        }
      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

      this.isLoad = false;
    },
  },

  watch: {
    params() {
      this.data = {...this.params};
    }
  },

  created() {
    this.data = {...this.params};
  },

  computed: {
    estimateSumPrice() {
      const estimateFundamentPrice = this.data.estimateFundamentPrice ? parseFloat(this.data.estimateFundamentPrice) : 0
      const estimateKorobkaPrice = this.data.estimateKorobkaPrice ? parseFloat(this.data.estimateKorobkaPrice) : 0
      const estimatePerekritiePrice = this.data.estimatePerekritiePrice ? parseFloat(this.data.estimatePerekritiePrice) : 0
      const estimateArmpoiasPrice = this.data.estimateArmpoiasPrice ? parseFloat(this.data.estimateArmpoiasPrice) : 0

      const estimateStiazkaPrice = this.data.estimateStiazkaPrice ? parseFloat(this.data.estimateStiazkaPrice) : 0
      const estimateKrishaPrice = this.data.estimateKrishaPrice ? parseFloat(this.data.estimateKrishaPrice) : 0
      const estimateZaborPrice = this.data.estimateZaborPrice ? parseFloat(this.data.estimateZaborPrice) : 0
      const estimateSepticPrice = this.data.estimateSepticPrice ? parseFloat(this.data.estimateSepticPrice) : 0

      const estimateElektrichestvoPrice = this.data.estimateElektrichestvoPrice ? parseFloat(this.data.estimateElektrichestvoPrice) : 0

      const estimateOknaDveriVorotaPrice = this.data.estimateOknaDveriVorotaPrice ? parseFloat(this.data.estimateOknaDveriVorotaPrice) : 0
      const estimateOtoplenieVodaKnsPrice = this.data.estimateOtoplenieVodaKnsPrice ? parseFloat(this.data.estimateOtoplenieVodaKnsPrice) : 0
      const estimatePechIKaminPrice = this.data.estimatePechIKaminPrice ? parseFloat(this.data.estimatePechIKaminPrice) : 0

      return estimateFundamentPrice +
          estimateKorobkaPrice +
          estimatePerekritiePrice +
          estimateArmpoiasPrice +

          estimateStiazkaPrice +
          estimateKrishaPrice +
          estimateZaborPrice +
          estimateOknaDveriVorotaPrice +

          estimateElektrichestvoPrice +
          estimateSepticPrice +
          estimateOtoplenieVodaKnsPrice +
          estimatePechIKaminPrice
    },
  },

}
</script>

<style scoped>

</style>