import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from "@/views/HomeView";
import LoginView from "@/views/LoginView";
import Error404View from "@/views/Error404View";

import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import PriceListLv from "@/views/PriceListLv";
import HouseLv from "@/views/HouseLv";
import HouseDv from "@/views/HouseDv";
import CalculationEstimatesView from "@/views/CalculationEstimatesView";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'homeView',
        component: HomeView,
        meta: {
            title: 'Бастион CRM',
        }
    },

    {
        path: '/house',
        name: 'houseLv',
        component: HouseLv,
        meta: {
            title: 'Бастион CRM - реестр домов',
        }
    },

    {
        path: '/house/:id',
        name: 'houseDv',
        component: HouseDv
    },


    {
        path: '/price_list',
        name: 'priceListLv',
        component: PriceListLv,
        meta: {
            title: 'Бастион - настройка цен',
        }
    },

    {
        path: '/login',
        name: 'loginView',
        component: LoginView,
        meta: {
            title: 'Бастион - личный кабинет',
        }
    },

    {
        path: '/calculation_estimates',
        name: 'calculationEstimatesView',
        component: CalculationEstimatesView,
        meta: {
            title: 'Бастион - расчет смет',
        }
    },



    {
        path: '*',
        name: 'page404View',
        component: Error404View,
        meta: {
            title: 'Страница не найдена',
            description:'Страница не найдена'
        }
    },

]

const router = new VueRouter({
    routes,
    mode: "history",
    scrollBehavior: function(to, ) {
        if (to.hash) {
            return {selector: to.hash}

        } else {
            return { x: 0, y: 0 }
        }
    },
})


router.afterEach((to,) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || LOCAL_CONFIG.defaultTitle;
    });
});


export default router
