<template>
  <div class="my-3" v-if="[1,2,3,5].includes(user.role)">
    <div class="container ">
      <div class="row">
        <div class="col-12 mb-5 d-flex">
          <h1 class="text-uppercase  mb-1"><b>Реестр домов</b></h1>
          <div class="ms-auto">
            <button class="btn btn-warning px-6 br-20 w-100" @click="$refs.houseAddModal.open()">Добавить
            </button>
            <house-add-modal
                ref="houseAddModal"
                @showAlert="$emit('showAlert', $event)"
            />
          </div>
        </div>


        <div class="col-12 mb-5">

          <div class="row">

            <div class="col-12 col-md-12 mb-3">
              <label for=""><small>Адрес/клент/менеджер</small></label>
              <v-text-field
                  class="pt-0"
                  label=""
                  v-model="filterAndSort.term"
              />
            </div>

            <div class="col-12 col-md-4 ">
              <label for=""><small>Дата сделки</small></label>
              <div class="d-flex w-100">
                <div class="w-100 ">
                  <v-text-field
                      type="date"
                      class="mb-2 pt-0 w-100"
                      label=""
                      v-model="filterAndSort.dataSdelkiS"
                  />
                </div>
                <div class="py-2 px-2">-</div>
                <div class="w-100">
                  <v-text-field
                      type="date"
                      class="mb-2 pt-0 w-100"
                      label=""
                      v-model="filterAndSort.dataSdelkiPo"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 ">
              <label for=""><small>Дата сдачи</small></label>
              <div class="d-flex w-100">
                <div class="w-100 ">
                  <v-text-field
                      type="date"
                      class="mb-2 pt-0 w-100"
                      label=""
                      v-model="filterAndSort.dataSdachiS"
                  />
                </div>
                <div class="py-2 px-2">-</div>
                <div class="w-100">
                  <v-text-field
                      type="date"
                      class="mb-2 pt-0 w-100"
                      label=""
                      v-model="filterAndSort.dataSdachiPo"
                  />
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4">
              <label for=""><small>Архивность</small></label>
              <v-select
                  class="mb-2 pt-0"
                  label=""
                  item-text="label"
                  item-value="id"
                  v-model="filterAndSort.isArchive"
                  :items="yesNoNullList"
              />
            </div>

          </div>

        </div>



        <div class="col-12 mb-3 col-md-12">


          <v-progress-linear
              indeterminate
              v-if="isLoad"
              class="mt-3"
              color="orange"
          ></v-progress-linear>

          <div class="card shadow br-20 px-8 py-6">
            <v-simple-table v-if="data.length > 0">
              <template v-slot:default>
                <thead class="border ">
                <tr>
                  <th class="text-left border bg-light text-center">
                    ID
                  </th>
                  <th class="text-left bg-light">
                    Адрес
                  </th>
                  <th class="text-left bg-light">
                    Статус
                  </th>
                  <th class="text-left bg-light">
                    Клиент
                  </th>
                  <th class="text-left bg-light">
                    Дата сделки
                  </th>
                  <th class="text-left bg-light">
                    Срок сдачи
                  </th>
                  <th class="text-left bg-light">
                    Менеджер
                  </th>
                  <th class="text-left bg-light">
                    Цена по договору
                  </th>
                </tr>
                </thead>
                <tbody class="border ">
                <tr
                    v-for="item in data"
                    :key="item.id"
                    @click="open(item.id)"
                >
                  <td class="border pointer-cursor text-center">{{ item.id }} <span class="text-danger" v-if="item.isArchive"><br>(в архиве)</span></td>
                  <td class="border pointer-cursor">{{ item.address }}</td>
                  <td class="border pointer-cursor"><span v-if="item.status">{{ item.status }}</span><span v-else>Без статуса</span>
                  </td>
                  <td class="border pointer-cursor">{{ item.client }}</td>
                  <td class="border pointer-cursor">{{ item.dateTrade }}</td>
                  <td class="border pointer-cursor">{{ item.dateEndBuild }}</td>
                  <td class="border pointer-cursor">{{ item.manager }}</td>
                  <td class="border pointer-cursor">{{ item.priceContract }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <b class="text-center" v-else>Поиск не дал результатов...</b>
          </div>

          <v-progress-linear
              indeterminate
              v-if="isLoad"
              class="mt-3"
              color="orange"
          ></v-progress-linear>


        </div>


        <div class="col-12">
          <v-pagination
              color="orange"
              circle
              v-model="filterAndSort.page"
              class="my-4"
              :length="pageCount"
          ></v-pagination>
        </div>
      </div>


      <!--          <v-skeleton-loader-->
      <!--              class="mx-auto shadow br-20"-->
      <!--              type="table"-->
      <!--          ></v-skeleton-loader>-->


    </div>


  </div>
</template>

<script>


import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import HouseAddModal from "@/components/house/HouseAddModal";

export default {
  name: "HouseLv",
  components: {HouseAddModal},
  props: ['user'],
  data() {
    return {
      data: [],
      pageCount: 1,
      isLoad: false,
      yesNoNullList: LOCAL_CONFIG.yesNoNullList,
      filterAndSort: {
        page: 1,
        isArchive: false,
        address: '',
        client: '',
        manager: '',
      }
    }
  },

  methods: {
    async getData() {
      this.isLoad = true

      try {
        const url = `${LOCAL_CONFIG.urls.house}?` + new URLSearchParams(this.filterAndSort);
        const response = await fetch(url, {method: 'get'});
        const responseData = await response.json();

        this.data = responseData.data;
        this.pageCount = responseData.pageCount;

      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

      this.isLoad = false;
    },

    open(id) {
      this.$router.push({name: 'houseDv', params: {id: id}})
    }
  },

  async created() {
    await this.getData()
    this.$emit('loadComplete',);
  },

  watch: {
    'filterAndSort.page'() {
      this.getData();
    },
    'filterAndSort.term'() {
      this.filterAndSort.page = 1;
      this.getData();
    },
    'filterAndSort.dataSdelkiS'() {
      this.filterAndSort.page = 1;
      this.getData();
    },
    'filterAndSort.dataSdelkiPo'() {
      this.filterAndSort.page = 1;
      this.getData();
    },
    'filterAndSort.dataSdachiS'() {
      this.filterAndSort.page = 1;
      this.getData();
    },
    'filterAndSort.dataSdachiPo'() {
      this.filterAndSort.page = 1;
      this.getData();
    },

    'filterAndSort.isArchive'() {
      this.filterAndSort.page = 1;
      this.getData();
    },
  }


}
</script>

<style scoped>

</style>