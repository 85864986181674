<template>


  <v-dialog
      v-model="dialogShow"
      width="500"
  >
    <v-card class="br-20 px-3 py-3" elevation="5">
      <form @submit.prevent="save()">

        <v-card-text>

          <div>
            <div class="h4 mb-5">Добавить дом</div>
          </div>


          <div class="mb-3">
            <label for="">ФИО клиента</label>
            <v-text-field
                class="mb-3 pt-0"
                label=""
                v-model="data.client"
            />
          </div>

          <div class="">
            <label for="">Телефон клиента</label>
            <v-text-field
                class="pt-0"
                label=""
                v-model="data.clientPhone"
            />
          </div>

              <div class="mb-3">
            <label for="">Адрес</label>
            <v-text-field
                class="mb-3 pt-0"
                label=""
                v-model="data.address"
            />
          </div>



        </v-card-text>

        <v-card-actions class="d-flex px-5">
          <div class="d-block ">
            <v-btn
                plain
                color="grey"
                class="px-0 br-20"
                @click="dialogShow=false"
            >
              Отмена
            </v-btn>
          </div>

          <div class="d-block ms-auto">
            <v-btn
                type="submit"
                color="warning"
                class="px-5 br-20"
                :loading="isLoad"
                tile>
              Добавить
            </v-btn>
          </div>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>


</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  props: ['params',],

  data: () => ({
    isLoad: false,
    data: {clientPhone: '', client: '', address: ''},
    dialogShow: false,
  }),


  methods: {

    open() {
      this.data = {
        clientPhone: '',
        client: '',
        address: '',
      }

      this.dialogShow = true;
    },


    async save() {
      if (! this.data.client || ! this.data.clientPhone){
        this.$emit('showAlert', {'color': 'red', text: 'Укажите ФИО и телефон клиента'});
        return false
      }

      const url = `${LOCAL_CONFIG.urls.house}`;

      this.isLoad = true

      try {
        const response = await fetch(url, {
          method: 'post',
          body: JSON.stringify(this.data),
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });

        //обработка если всё ок
        if (response.status === 200 || response.status === 201) {
          this.$emit('showAlert', 'Операция прошла успешна');
          const responceData = await response.json();
          window.location.href = `/house/${responceData.id}`
        }
        //обработки если не всё ок
        else {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
        }
      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

      this.isLoad = false;
    },

  },


}
</script>

<style scoped lang="scss">
@import 'src/assets/const';

</style>