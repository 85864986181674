<template>


  <div id="app">

    <v-app class="">

      <header-block v-if="user.isAuth" v-show="isLoad" :user="user"/>
      <v-main class="bg-light">
        <app-loader v-if="!isLoad"/>

        <div v-show="isLoad" class="h-100">
          <transition name="fade" mode="out-in">
            <router-view
                :user="user"
                @loadComplete="isLoad = true"
                @showAlert="showAlert($event)"
            ></router-view>
          </transition>
        </div>

        <v-snackbar
            class="br-20"
            :color="snackbarColor"
            v-model="snackbar"
        >
          <span class="text-white font-weight-medium">{{ snackbarText }}</span>

          <template v-slot:action="{ attrs }">
            <v-btn

                class="text-white br-20"
                plain
                v-bind="attrs"
                @click="snackbar = false"
            >
              Ок
            </v-btn>
          </template>
        </v-snackbar>

      </v-main>
      <footer-block v-show="isLoad"/>
    </v-app>
  </div>
</template>

<style lang="scss">
</style>

<script>
import AppLoader from "@/components/include/AppLoader";

import HeaderBlock from "@/components/include/HeaderBlock";
import FooterBlock from "@/components/include/FooterBlock";
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";


export default {


  components: {
    AppLoader,
    HeaderBlock,
    FooterBlock,
  },

  async created() {
    await this.checkUser();

    this.$router.beforeEach((to, from, next) => {
      if (!to.hash) {
        this.isLoad = false;
      }
      next()
    })


  },

  methods: {

    showAlert(message) {
      if (message.color) {
        this.snackbarText = message.text;
        this.snackbarColor = message.color;
      } else {
        this.snackbarText = message;
        this.snackbarColor = 'green';
      }
      this.snackbar = true

    },

    async checkUser() {

      const url = LOCAL_CONFIG.urls.login;
      const response = await fetch(url,
          {
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CookieHelper.getCookie('csrftoken')
            },
          },
      );
      this.user = await response.json();

      if (this.user.isAuth === false && window.location.pathname.split("/").pop() !== "login") {
        window.location.href = "/login"
      }

    }


  },

  data() {
    return {
      isLoad: false,
      snackbar: false,
      snackbarText: "",
      snackbarColor: "white",
      user: {},
    }
  },


}
</script>