<template>

  <div class="">

    <v-progress-linear
        indeterminate
        v-if="isLoad"
        class="mt-3"
        color="orange"
    ></v-progress-linear>
    <div v-if="!isLoad">
      <table class="table">
        <thead>
        <tr>
          <th>Файл</th>
          <th>Дата добавления</th>
          <th>Кто добавил</th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in params.fileSet" :key="item.id"
        >
          <td><a target="_blank" class="" :href="item.file">{{ item.fileName }} <span class="text-warning small"><b>(открыть)</b></span></a>
          </td>
          <td>{{ item.date }}</td>
          <td>{{ item.user }}</td>
          <td>
              <v-btn
                    v-if="!params.zapretNaRedaktirovanie"
                    color="red"
                    plain
                    small
                    class="px-5 br-20 btn-sm"
                    :loading="isLoad"
                    @click="deleteFile(item.id)"
                    tile>
                  Удалить
                </v-btn>

          </td>
        </tr>
        </tbody>
      </table>
    </div>


    <button class="btn btn-warning shadow-sm br-20 px-5 "   v-if="!params.zapretNaRedaktirovanie" @click="open()">
      Добавить
    </button>


    <v-dialog
        v-model="dialogShow"
        width="500"
    >
      <v-card class="br-10 px-3 py-3" elevation="5">
        <form @submit.prevent="save()">

          <v-card-text>

            <div>
              <div class="h4 mb-5">Добавить файл</div>
            </div>

            <div>
              <label for="">Название файла</label>
              <v-text-field
                  class="mb-3 pt-0"
                  label=""
                  v-model="data.name"
              />
            </div>

            <div class="mt-3">
              <label for="">Файл</label>
              <v-file-input

                class="pt-0"
                  label=""
                  v-model="data.file"
                  requred
                  show-size
                  counter
                  multiple
                  type="file"
                  name="file"

              ></v-file-input>
            </div>


          </v-card-text>

          <v-card-actions class="d-flex px-5">
            <div class="d-block ">
              <v-btn
                  plain

                  class="px-0 br-20"
                  @click="dialogShow=false"
              >
                Отмена
              </v-btn>
            </div>

            <div class="d-block ms-auto">
              <v-btn

                  type="submit"
                  color="warning"
                  class="px-5 br-20"
                  :loading="isLoad"
                  tile>
                Добавить
              </v-btn>
            </div>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  props: ['params',],

  data: () => ({
    isLoad: false,
    data: {name: '', file: ''},
    dialogShow: false,
  }),


  methods: {

    open() {
      this.data = {
        name: '',
        file: null,
      }

      this.dialogShow = true;
    },

    async deleteFile(id) {
      this.isLoad = true;
      try {

        const url = `${LOCAL_CONFIG.urls.houseFile}/` + id;
        const response = await fetch(url, {
          method: 'delete',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });
        if (response.status === 200 || response.status === 201) {
          this.$emit('showAlert', 'Файл удален');
          this.$emit('updateData',);
        } else {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
        }
      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

      this.isLoad = false;
    },


    async save() {



      const url = `${LOCAL_CONFIG.urls.houseFile}`;
      if (this.data.file === null) {
        this.$emit('showAlert', {'color': 'red', text: 'Прикрепите пожалуйста файл'});
        return
      }

      this.isLoad = true

      let data = new FormData()
      data.append('file', this.data.file[0]);
      data.append('name', this.data.name);
      data.append('houseId', this.params.id);

      const response = await fetch(url, {
        method: 'post',
        body: data,
        credentials: 'include',
        mode: 'cors',
        headers: {
          'X-CSRFToken': CookieHelper.getCookie('csrftoken')
        },
      });

      //обработка если всё ок
      if (response.status === 200 || response.status === 201) {
        this.$emit('showAlert', 'Операция прошла успешна');
        this.dialogShow = false;
        this.$emit('updateData');
      }
      //обработки если не всё ок
      else {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

      this.isLoad = false;
    },

  },


}
</script>

<style scoped lang="scss">
@import 'src/assets/const';

.prev, .next {
  position: absolute;
  top: 60px;
  font-size: 12pt;
  cursor: pointer;
  color: #fff;
  background: $color-primary;
  padding: 5px 3px 5px 3px;
  box-sizing: border-box;

  &:hover {
    background-color: $color-primary-hover;
  }
}

.prev {
  left: -19px;
  border-radius: 100% 0 0 100%;
}


.next {
  right: -19px;
  border-radius: 0 100% 100% 0;
}

.card-body {
  min-height: 10px;
}

</style>