<template>
  <div>
    <div>

      <v-expansion-panels class="px-1">

        <!-- переменные -->
        <v-expansion-panel v-if="!isLoad" v-show="!isLoadKonstruktivi" class=" br-20 mb-5">
          <v-expansion-panel-header>
            <b class="h4 ">1. Переменные</b>
          </v-expansion-panel-header>

          <v-expansion-panel-content>

            <!-- дом -->
            <v-row class="mb-6">
              <v-col cols="12" md="12">
                <h3>Дом</h3>
              </v-col>

              <v-col cols="12" md="6" v-for="(val_list, index) in peremeniePoluchenieIzGoogle['dom']"
                     :key="index">
                <label class="small">{{ index|del_first_3_char }}</label>
                <v-select
                    @change="$forceUpdate()"
                    class="mb-2 pt-0"
                    label=""
                    v-model="peremenieUstanoclenieCrm['dom'][index]"
                    :items="val_list"
                />
              </v-col>

            </v-row>

            <!-- гараж -->
            <v-row class="mb-6">
              <v-col cols="12" md="12">
                <h3>Гараж | котельня</h3>
              </v-col>
              <v-col cols="12" md="6" v-for="(val_list, index) in peremeniePoluchenieIzGoogle['garaz']"
                     :key="index"
                     v-show="peremenieUstanoclenieCrm['garaz']['01_Наличие гаража | котельни'] === 'Да' || index==='01_Наличие гаража | котельни'">
                <label class="small">{{ index|del_first_3_char }}</label>
                <v-select
                    class="mb-2 pt-0"
                    label=""
                    @change="$forceUpdate()"
                    v-model="peremenieUstanoclenieCrm['garaz'][index]"
                    :items="val_list"
                />
              </v-col>

            </v-row>


            <!-- баня -->
            <v-row>
              <v-col cols="12" md="12">
                <h3>Баня</h3>
              </v-col>

              <v-col cols="12" md="6" v-for="(val_list, index) in peremeniePoluchenieIzGoogle['bania']"
                     :key="index"
                     v-show="peremenieUstanoclenieCrm['bania']['01_Наличие бани'] === 'Да' || index==='01_Наличие бани'"
              >
                <label class="small">{{ index|del_first_3_char }}</label>
                <v-select
                    @change="$forceUpdate()"
                    class="mb-2 pt-0"
                    label=""
                    v-model="peremenieUstanoclenieCrm['bania'][index]"
                    :items="val_list"
                />
              </v-col>

            </v-row>


            <v-btn @click="setPeremenie" color="primary" class="br-20">Далее</v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- параметры -->

        <smeta-parametri-doma :parametriDoma="parametriDoma" v-if="parametriDoma" v-show="!isLoadKonstruktivi"
                              @isLoad="isLoadKonstruktivi=$event"

                              @changeSpisokStoimosteiKonstruktivov="changeSpisokStoimosteiKonstruktivov($event)"
                              @showAlert="$emit('showAlert', $event)"/>


        <!-- смета -->
        <div v-if="spisokStoimosteiKonstruktivov && !isLoad" class="w-100">
          <div v-for="(konstruktiv, index) in spisokStoimosteiKonstruktivov" :key="index">
            <smeta-panel2 :konstruktiv="konstruktiv" @isLoad="isLoad=$event" @showAlert="$emit('showAlert', $event)" @save="setPeremenie"/>

          </div>
        </div>


      </v-expansion-panels>

    </div>

    <div style="height: 150px" v-if="isLoad || isLoadKonstruktivi">
      <app-loader/>
    </div>

  </div>
</template>

<script>
import SmetaParametriDoma from "@/components/smeta/SmetaParametriDoma";

import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import SmetaPanel2 from "@/components/smeta/SmetaPanel2";
import AppLoader from "@/components/include/AppLoader";
import CookieHelper from "@/plugins/cookieHelper";


export default {
  components: {AppLoader, SmetaPanel2, SmetaParametriDoma},


  data() {
    return {
      isLoadPaneli: false,
      isLoad: true,
      isLoadKonstruktivi: false,
      alert: '',
      spisokStoimosteiKonstruktivov: null,
      parametriDoma: null,
      peremenieUstanoclenieCrm: {
        dom: {},
        garaz: {},
        bania: {},
      },
      peremeniePoluchenieIzGoogle: {
        dom: {},
        garaz: {},
        bania: {},
      },
    }
  },


  methods: {

    changeSpisokStoimosteiKonstruktivov(data) {
      this.spisokStoimosteiKonstruktivov = data;
    },

    async getPeremenie() {

      try {
        const url = `${LOCAL_CONFIG.urls.pereminieGoogleSmetaApi}`;
        const response = await fetch(url, {method: 'get'});
        this.peremeniePoluchenieIzGoogle = await response.json();


        for (const [key, value] of Object.entries(this.peremeniePoluchenieIzGoogle.dom)) {
          this.peremenieUstanoclenieCrm.dom[key] = value[0]
        }

        for (const [key, value] of Object.entries(this.peremeniePoluchenieIzGoogle.bania)) {
          this.peremenieUstanoclenieCrm.bania[key] = value[0]
        }

        for (const [key, value] of Object.entries(this.peremeniePoluchenieIzGoogle.garaz)) {
          this.peremenieUstanoclenieCrm.garaz[key] = value[0]
        }

        this.isLoad = false;
      } catch (e) {
        this.isLoad = false;
        this.alert = "Ошибка при обращение к серверу. Попробуйте обновить страницу";
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

    },

    async setPeremenie() {
      this.isLoad = true
      this.parametriDoma = null;
      this.spisokStoimosteiKonstruktivov = null;

      // $forceUpdate()

      try {
        const url = `${LOCAL_CONFIG.urls.pereminieGoogleSmetaApi}`;
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(this.peremenieUstanoclenieCrm),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });
        this.parametriDoma = await response.json();

        this.sag = 2

      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }
      this.isLoad = false;
    }
  },


  async created() {
    this.getPeremenie()
  },

  filters: {
    del_first_3_char: function (value) {
      return value.substring(3, 255)
    }
  }
}
</script>

<style scoped>

</style>