<template>
  <v-expansion-panel class="shadow br-20 mb-5">


    <v-expansion-panel-header>
      <div>
        <div class="mb-4">
          <b class="h3 ">{{ konstruktiv.name }}</b>
        </div>
        <div>
          <v-chip class="me-3 shadow" color="success">Всего:
            {{ konstruktiv.sumVsego.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }} руб.
          </v-chip>
          <v-chip class="me-3 shadow text-white" color="blue">Материалы:
            {{ konstruktiv.materialSum.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}
            руб.
          </v-chip>
          <v-chip class="me-3 shadow" color="warning">Работы:
            {{ konstruktiv.jobSum.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }} руб.
          </v-chip>
        </div>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <hr>
      <div v-for="(podkonstruktivi, index) in konstruktiv.items" :key="index">

        <h4>{{ podkonstruktivi.name }}</h4>
        <div>
          <v-chip class="me-3 shadow" color="success">Всего:
            {{ podkonstruktivi.sumVsego.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }} руб.
          </v-chip>
          <v-chip class="me-3 shadow text-white" color="blue">Материалы:
            {{ podkonstruktivi.materialSumSNacenkoi.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}
            руб.
          </v-chip>
          <v-chip class="me-3 shadow" color="warning">Работы:
            {{ podkonstruktivi.jobSumSNacenkoi.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }} руб.
          </v-chip>

        </div>


        <div v-if="podkonstruktivi.materialSum > 0">
          <h5 class="my-3">Материалы</h5>
          <div class="table-responsive">
            <table class="table table-bordered align-middle text-center small ">
              <tbody>
              <!-- шапка -->
              <tr class="font-weight-bold">
                <td>Название</td>
                <td>Материал</td>
                <td>Ед. измерения</td>
                <td>Цена, руб</td>
                <td>Количество</td>
                <td>Итого, руб</td>
                <td>Учитывать</td>
              </tr>

              <tr v-for="item in podkonstruktivi.materialList" :key="item.label">
                <td>{{ item.nazvanie }}</td>
                <td>{{ item.material }}</td>
                <td>{{ item.edIzmerenia }}</td>
                <td>{{ item.price }}</td>
                <td>{{ item.kolvo.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</td>
                <td>{{ item.itogo.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</td>
                <td class="">
                  <div class="d-flex align-items-center w-100 text-center align-content-center justify-center">
                    <v-switch v-model="item.uchitivat"/>
                  </div>
                </td>
              </tr>

              <tr class="font-weight-bold ">
                <td colspan="5" class="text-right">ИТОГО:</td>
                <td colspan="2">{{
                    podkonstruktivi.materialSum.toLocaleString('ru-RU', {'maximumFractionDigits': 2})
                  }}
                </td>
              </tr>

              <tr class="font-weight-bold ">
                <td colspan="5" class="text-right">Наценка(%):</td>
                <td colspan="2">
                  <v-text-field
                      class="mb-2 pt-0"
                      label=""
                      v-model="podkonstruktivi.materialNacenka"
                  />
                </td>
              </tr>

              <tr class="font-weight-bold ">
                <td colspan="5" class="text-right">ИТОГО с наценкой:</td>
                <td colspan="2">{{
                    podkonstruktivi.materialSumSNacenkoi.toLocaleString('ru-RU', {'maximumFractionDigits': 2})
                  }}
                </td>
              </tr>


              </tbody>
            </table>
          </div>
        </div>

        <div v-if="podkonstruktivi.jobSum > 0">
          <h5 class="my-3">Работы</h5>
          <div class="table-responsive">
            <table class="table table-bordered align-middle text-center small ">
              <tbody>
              <!-- шапка -->
              <tr class="font-weight-bold">
                <td>Название</td>
                <td>Рабочие</td>
                <td>Ед. измерения</td>
                <td>Цена, руб</td>
                <td>Количество</td>
                <td>Итого, руб</td>
                <td>Учитывать</td>
              </tr>

              <tr v-for="item in podkonstruktivi.jobList" :key="item.label">
                <td>{{ item.nazvanie }}</td>
                <td>{{ item.rabochie }}</td>
                <td>{{ item.edIzmerenia }}</td>
                <td>{{ item.price }}</td>
                <td>{{ item.kolvo.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</td>
                <td>{{ item.itogo.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</td>
                <td class="">
                  <div class="d-flex align-items-center w-100 text-center align-content-center justify-center">
                    <v-switch v-model="item.uchitivat"/>
                  </div>
                </td>
              </tr>

              <tr class="font-weight-bold ">
                <td colspan="5" class="text-right">ИТОГО:</td>
                <td colspan="2">{{ podkonstruktivi.jobSum.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</td>
              </tr>

              <tr class="font-weight-bold ">
                <td colspan="5" class="text-right">Наценка(%):</td>
                <td colspan="2">
                  <v-text-field
                      class="mb-2 pt-0"
                      label=""
                      v-model="podkonstruktivi.jobNacenka"
                  />
                </td>
              </tr>

              <tr class="font-weight-bold ">
                <td colspan="5" class="text-right">ИТОГО с наценкой:</td>
                <td colspan="2">{{
                    podkonstruktivi.jobSumSNacenkoi.toLocaleString('ru-RU', {'maximumFractionDigits': 2})
                  }}
                </td>
              </tr>


              </tbody>
            </table>

            <v-btn @click="save" color="primary" class="br-20">Обновить</v-btn>
          </div>
        </div>
      </div>


    </v-expansion-panel-content>

    <!--      -->

    <!--      &lt;!&ndash; работы &ndash;&gt;-->
    <!--      <div v-if="danieKonstruktiva.summi && danieKonstruktiva.summi.summaRabot > 0">-->
    <!--        <h5 class="my-3">Работы</h5>-->
    <!--        <div class="table-responsive">-->
    <!--          <table class="table table-bordered align-middle text-center small ">-->
    <!--            <tbody>-->
    <!--            &lt;!&ndash; шапка &ndash;&gt;-->
    <!--            <tr class="font-weight-bold">-->
    <!--              <td>Название</td>-->
    <!--              <td>Рабочие/машины</td>-->
    <!--              <td>Ед. измерения</td>-->
    <!--              <td>Цена, руб</td>-->
    <!--              <td>Количество</td>-->
    <!--              <td>Итого, руб</td>-->
    <!--            </tr>-->

    <!--            <tr v-for="item in danieKonstruktiva.jobs" :key="item.label">-->
    <!--              <td>{{ item.title }}</td>-->
    <!--              <td>{{ item.value }}</td>-->
    <!--              <td>{{ item.edenizaIzmerenia }}</td>-->
    <!--              <td>{{ item.price }}</td>-->
    <!--              <td>{{ item.kolvo.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</td>-->
    <!--              <td>{{ item.sum.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}-->
    <!--                <formula-helper class="ms-2" :text="item.formulaLabel"/>-->
    <!--              </td>-->
    <!--            </tr>-->

    <!--            <tr class="font-weight-bold " v-if="danieKonstruktiva.summi">-->
    <!--              <td colspan="5" class="text-right">ИТОГО:</td>-->
    <!--              <td>{{ danieKonstruktiva.summi.summaRabot.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</td>-->
    <!--            </tr>-->


    <!--            </tbody>-->
    <!--          </table>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      &lt;!&ndash; материалы &ndash;&gt;-->
    <!--      <div v-if="danieKonstruktiva.summi && danieKonstruktiva.summi.summaMaterialov > 0">-->


    <!--      <div v-if="danieKonstruktiva.summi && danieKonstruktiva.summi.summaVsego > 0">-->
    <!--        <div class="h4"><b>Итого: {{ danieKonstruktiva.summi.summaVsego.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}-->
    <!--          руб.</b></div>-->
    <!--      </div>-->


  </v-expansion-panel>
</template>

<script>

import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {

  props: ['konstruktiv',],


  methods: {

    async save() {

      try {


        const url = `${LOCAL_CONFIG.urls.konsruktiviGoogleSmetaApi}`;
        const response = await fetch(url, {
          method: 'post',
          body: JSON.stringify(this.konstruktiv),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });
        if (response.status === 200 || response.status === 201) {
          this.$emit('showAlert', 'Данные сохранены');
          // this.$emit('save', '');
        } else if (response.status === 400) {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка. Проверьте пожалуйста что все поля заполнены'});
        } else {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка. подробности в консоли браузера'});
        }
      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

    }
  }


}
</script>

<style scoped>

</style>