<template>
  <div class="my-3" v-if="[1,4].includes(user.role)">
    <div class="container ">
      <h1 class="text-uppercase  mb-4"><b>Настройка цен</b></h1>


      <div class="card br-20 px-8 py-6">
        <v-tabs
            v-model="tab"
            color="orange"
        >
          <v-tab>Цены для договора</v-tab>
          <v-tab>Цены для сметы old</v-tab>
          <v-tab >История изменения цен</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="py-4 ">
          <v-tab-item>
            <price-contract-form
                @showAlert="$emit('showAlert', $event)"
            />
          </v-tab-item>

          <v-tab-item>
              <price-estimate-form
                @showAlert="$emit('showAlert', $event)"
            />
          </v-tab-item>
          <v-tab-item>
             <price-contract-history
                @showAlert="$emit('showAlert', $event)"
            />
          </v-tab-item>

        </v-tabs-items>
      </div>


    </div>


  </div>
</template>

<script>


import PriceContractForm from "@/components/priceList/PriceContractForm";
import PriceContractHistory from "@/components/priceList/PriceContractHistory";
import PriceEstimateForm from "@/components/priceList/PriceEstimateForm";
export default {
  name: "PriceListLv",
  props:['user'],
  components: {PriceEstimateForm, PriceContractForm, PriceContractHistory},
  data() {
    return {
      tab: 0,
    }
  },

  async created() {
    this.$emit('loadComplete',);
  },


}
</script>

<style scoped>

</style>