<template>
  <footer class="footer  shadow pt-2">
    <div class="container py-0">
      <div class="d-flex">


        <div class=" m-auto">

          <a href="https://kirillgladkikh.ru/" target="_blank" class="small text-decoration-none text-white">
            <div
                class="br-20 text-white text-center d-flex flex-row align-items-center border-0 justify-content-center">
              <div class="me-3 mb-3 ">
                <svg class="svg-logo"  viewBox="0 0 127.01 357.13">
                  <path
                      d="M86.23,92.6h-16.15c-.06,1.17-.16,2.02-.14,2.86,.08,5.19,.09,10.39,.31,15.58,.09,2.14-.45,2.94-2.75,2.88-5.27-.14-10.55,.08-15.82,.02-2.07-.02-3,.46-2.87,2.81,.29,5.34,.33,10.7,.37,16.05,.01,1.83,.63,2.54,2.53,2.49,5.43-.14,10.87-.17,16.31-.12,1.76,.01,2.43-.61,2.4-2.36-.07-5.51,.02-11.03-.12-16.54-.04-1.8,.57-2.4,2.3-2.4,5.43,0,10.87-.05,16.3-.19,1.61-.04,2.09,.88,2.35,2.12,1.84,9.04,3.67,18.08,5.48,27.13,.81,4.04,1.59,8.08,2.52,12.81-2.14,0-4.02-.16-5.85,.08-.78,.1-2.06,1.02-2.07,1.59-.15,6.37-.09,12.74-.09,19.3,3.49,0,6.58,.1,9.67-.04,1.93-.08,2.75,.49,3.16,2.52,5.07,25.4,10.25,50.78,15.41,76.16,2.47,12.16,4.95,24.31,7.45,36.46,.34,1.66-.2,2.86-1.39,4.03-9.21,9.09-18.38,18.23-27.55,27.37-10.92,10.89-21.86,21.77-32.71,32.73-1.54,1.56-2.43,1.58-3.99,.02-19.88-19.91-39.81-39.76-59.76-59.59-1.39-1.38-1.76-2.63-1.33-4.64,5.5-26.15,10.9-52.31,16.25-78.49,4.46-21.83,8.78-43.69,13.23-65.52,3.97-19.46,8.02-38.91,12.07-58.36,1.03-4.93,1.09-4.91,6.03-4.91,11.35,0,22.7,.07,34.05-.02,2.38-.02,3.54,.68,3.63,3.15,.06,1.56,.48,3.11,.8,5.02Zm-15.99,105.3c6.53,.02,13.07,.09,19.6-.04,.53-.01,1.45-1.36,1.48-2.12,.15-3.35,.03-6.7,.01-10.06,0-2.85,0-5.71,0-8.75h-20.59c-.07-1.21-.17-2.24-.19-3.27-.07-5.11-.16-10.22-.15-15.33,0-1.5-.43-2.16-2.03-2.13-5.75,.1-11.5,.12-17.25,.11-1.48,0-1.93,.59-1.9,2,.11,5.51,.19,11.02,.15,16.53-.01,1.95,.62,2.66,2.62,2.61,5.03-.14,10.06-.09,15.1-.12,.93,0,1.87,0,3.21,0,0,7.09,0,13.86,0,20.64-.78,.1-1.55,.29-2.33,.3-5.51,.1-11.02,.22-16.52,.21-1.76,0-2.37,.6-2.32,2.38,.12,5.26,.08,10.53,.12,15.8,0,.92,.09,1.85,.13,2.68,6.65,0,13.03-.01,19.4,0,1.71,0,1.61-1.17,1.6-2.31-.04-6.38-.09-12.77-.13-19.15Zm42.51,63.41c0-6.88,.04-13.26-.07-19.64,0-.49-1.06-1.38-1.63-1.39-5.83-.07-11.66-.04-17.49,.11-.59,.02-1.65,1.04-1.65,1.6-.08,5.91-.01,11.82,.12,17.72,.01,.54,.81,1.52,1.26,1.52,6.45,.1,12.91,.07,19.46,.07Z"></path>
                  <path
                      d="M90.56,50.22c-6.15,0-11.97,.08-17.8-.04-2.33-.05-3.25,.59-3.12,3.1,.26,5.26,.26,10.54,.22,15.82-.01,1.83,.68,2.34,2.41,2.25,3.51-.17,7.03-.22,10.55-.25,1.24-.01,2.49,.15,4.06,.25-.55,1.49-.84,2.74-1.47,3.79-.36,.6-1.25,1.25-1.91,1.25-13.43,.02-26.86-.03-40.28-.17-.74,0-1.86-.86-2.13-1.57-6.64-17.63-13.18-35.3-19.79-52.94-.46-1.22-.42-1.98,.62-2.9,6.69-5.93,13.2-12.05,20.05-17.79,1.29-1.08,3.83-.69,6.07-1.01,.12,2.43,.31,4.24,.28,6.06-.03,1.6,.65,2.03,2.16,2.01,5.43-.09,10.87,0,16.31-.13,1.97-.04,2.6,.58,2.6,2.59-.01,5.35,.22,10.7,.35,16.05,.03,1.26,.35,2.1,1.92,2.09,6.15-.06,12.29-.02,18.68-.02V7.75h-20.78V.15c4.62,0,9.22-.02,13.82,.03,.49,0,1.05,.38,1.45,.73,6.45,5.71,12.85,11.47,19.34,17.13,1.19,1.03,1.39,1.9,.79,3.29-1.01,2.33-1.22,5.54-2.94,6.86-1.6,1.23-4.73,.53-7.19,.59-5.16,.13-4.12,.17-4.15,4.15-.04,4.79,.03,9.59,.04,14.38,0,.78-.08,1.56-.16,2.9Z"></path>
                  <path
                      d="M90.8,58.45c0-2.35-.14-4.71,.04-7.04,.19-2.49,2.08-1.08,3.85-1.47-1.18,3.15-2.2,5.88-3.22,8.61l-.68-.1Z"></path>
                </svg>

              </div>
              <div class="text-uppercase text-primary ms-2 pb-2 font-weight-bold text-logo">
                <div class="">CRM разработана</div>
                <div>Гладких Кириллом</div>
              </div>
            </div>
          </a>
        </div>
      </div>

    </div>
  </footer>

</template>

<script>
export default {
  name: "FooterBlock"
}
</script>

<style scoped lang="scss">
@import 'src/assets/const';


.text-logo{
   font-size: 11pt !important;
}

.svg-logo {
  fill: $color-primary;
  width: 18px;
}

</style>