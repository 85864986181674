<template>
  <div class="">
    <v-progress-linear
        indeterminate
        :active="isLoad"
        class="mt-3"
        color="orange"
    ></v-progress-linear>


    <div class="" v-if="!isLoad">
      <div v-if="idNow">
        <h4 class="mb-5 "><b>Цена для смет #{{ data.id }} от {{ data.date }}</b></h4>
        <hr>
      </div>
      <div class="d-flex" v-else>
        <h4 class="mb-5 "><b>Цены для смет old</b></h4>

        <div class="ms-auto">
          <label for="">Поиск: </label>
          <v-text-field
              class="mb-0 mt-0 ms-3 pt-0 d-inline-block"
              type="text"
              v-model="search"
          />
        </div>

      </div>

      <div>

        <div>

          <div v-for="item in filteredData" :key="item.title">
            <v-text-field
                class="mb-2"
                :label="`[${item.notation}]  ${item.name}  (цена за ${item.unitMeasurement})`"
                type="text"
                :value="item.price"
            />
          </div>

        </div>


      </div>


      <button v-if="!idNow" class="btn btn-warning shadow-sm br-20 px-5 " :disabled="isLoad" @click="saveData">
        Сохранить
      </button>
    </div>

  </div>
</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  name: "PriceEstimateForm",


  data: () => ({
    data: [],
    search: '',
    isLoad: false,
  }),

  computed: {
    filteredData: function () {
      const self = this;
      if (this.search) {
        return this.data.filter(item => item.name.toLowerCase().includes(self.search.toLowerCase()) || item.notation.includes(self.search))
      }
      return this.data
    }
  },

  props: ['idNow'],

  methods: {

    async getData(idNow) {
      this.isLoad = true

      try {
        let url = ''
        if (idNow) {
          url = `${LOCAL_CONFIG.urls.priceEstimate}/${idNow}`;
        } else {
          url = `${LOCAL_CONFIG.urls.priceEstimateLast}`;
        }
        const response = await fetch(url, {method: 'get'});
        const responseData = await response.json();
        this.data = responseData.data;
      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

      this.isLoad = false;
    },

    async saveData() {
      this.isLoad = true

      try {
        let data = this.data;
        delete data.date;

        const url = `${LOCAL_CONFIG.urls.priceEstimate}`;
        const response = await fetch(url, {
          method: 'post',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });
        if (response.status === 200 || response.status === 201) {
          this.$emit('showAlert', 'Данные сохранены');
        } else if (response.status === 400) {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
        } else {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
        }
      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

      this.isLoad = false;
    },

  },

  created: async function () {
    await this.getData(this.idNow);
  },

}
</script>

<style scoped lang="scss">
@import 'src/assets/const';

</style>