<template>
  <header class="header shadow py-2">
    <div class="container">
      <div class="row">

        <v-navigation-drawer class="h-100"
                             v-model="drawer"
                             absolute
                             temporary
        >
          <v-list
              nav
              dense
          >
            <v-list-item-group
                v-model="group"
                active-class="text-warning"
            >
              <v-list-item>
                <v-list-item-title>
                  <router-link class="text-decoration-none" :to="{name:'houseLv'}">
                    Реестр домов
                  </router-link>
                </v-list-item-title>
              </v-list-item>


              <v-list-item>
                <v-list-item-title>
                  <router-link class=" text-decoration-none" :to="{name:'calculationEstimatesView'}">
                    Расчет смет
                  </router-link>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title>
                  <router-link class=" text-decoration-none" :to="{name:'priceListLv'}">
                    Настройки цен
                  </router-link>
                </v-list-item-title>
              </v-list-item>


              <v-list-item>
                <v-list-item-title>
                  <router-link class="h5 text-decoration-none" :to="{name:'loginView'}">
                    Авторизация
                  </router-link>
                </v-list-item-title>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>


        <div class="col-12 d-flex align-items-center">
          <div class="me-5">
            <router-link class="logo text-decoration-none" :to="{name:'homeView'}">
              <div class="d-flex align-items-center">
                <img class="logo mr-2" src="@/assets/logo2.png" alt="">
                <div class="logo-main text-uppercase text-black">Бастион CRM</div>
              </div>
            </router-link>
          </div>

          <div class="ms-5 o-d-none o-d-lg-flex">
            <div v-if="[1,2, 3, 5].includes(user.role)">
              <router-link class="header-link text-decoration-none" :to="{name:'houseLv'}">
                Реестр домов
              </router-link>
            </div>

            <div class="ms-5" v-if="[1,2].includes(user.role)">
              <router-link class="header-link text-decoration-none" :to="{name:'calculationEstimatesView'}">
                Расчет смет
              </router-link>
            </div>

            <div class="ms-5" v-if="[1,4].includes(user.role)">
              <router-link class="header-link text-decoration-none" :to="{name:'priceListLv'}">
                Настройки цен
              </router-link>
            </div>


          </div>

          <div class="ml-auto">
            <router-link class="o-d-none o-d-lg-inline header-link text-decoration-none" :to="{name:'loginView'}">
              <span v-if="user.username">{{user.username}}</span>
              <span v-else>Авторизация</span>
            </router-link>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="o-d-lg-none text-white"></v-app-bar-nav-icon>
          </div>
        </div>
      </div>

    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderBlock",
    props:['user'],
  data: () => ({
    drawer: false,
    group: null,
  }),

  watch: {
    group() {
      this.drawer = false
    },
  },
}
</script>

<style scoped lang="scss">
@import "src/assets/const";

.logo-main {
  font-size: 1.9rem;
  font-weight: bold;
  line-height: 1;
}

.logo-second {
  color: #666;
}

.header-link {
  color: #666;

  &:active, &:hover, &.router-link-active {
    color: #000;
  }
}

.logo{
  width: 30px;
}
</style>