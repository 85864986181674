<template>
  <div class="page404 h-100   d-flex justify-content-center align-items-center">

    <div class="text-center">
      <h1 class="text-uppercase h4 "><b>404 - страница не найдена ={</b></h1>
      <div class="mt-3">
        <router-link class="btn btn-primary br-20 shadow btn-sm " :to="{name:'homeView'}">
          Перейти на главную
        </router-link>
      </div>
</div>

  </div>
</template>

<script>

export default {
  name: "Error404View",


  async created() {
    this.$emit('loadComplete',);
  },
}
</script>

<style scoped>

</style>