import { render, staticRenderFns } from "./TablePostuplenie.vue?vue&type=template&id=b6163108&scoped=true&"
import script from "./TablePostuplenie.vue?vue&type=script&lang=js&"
export * from "./TablePostuplenie.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6163108",
  null
  
)

export default component.exports