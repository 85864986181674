// let baseUrl = 'http://127.0.0.1:8000';
let baseUrl = '';
export default {
    baseUrl: baseUrl,
    defaultTitle: 'Бастион CRM',

    urls: {
        imgUrl: `${baseUrl}/static/core/img/`,

        search: `${baseUrl}/api1/search`,

        priceContract: `${baseUrl}/api1/price_contract`,
        priceContractLast: `${baseUrl}/api1/price_contract_last`,

        priceEstimateLast: `${baseUrl}/api1/price_estimate_last`,
        priceEstimate: `${baseUrl}/api1/price_estimate`,


        calculationEstimateGetParamsApi: `${baseUrl}/api1/calculation_estimate_get_params`,
        calculationEstimateSetParamsApi: `${baseUrl}/api1/calculation_estimate_set_params`,

        house: `${baseUrl}/api1/house`,

        houseEtimate: `${baseUrl}/api1/house_etimate`,

        houseFile: `${baseUrl}/api1/house_file`,

        saveFile: `${baseUrl}/api1/save_file`,

        postuplenie: `${baseUrl}/api1/postuplenie`,

        zapretNaRedaktirovanie: `${baseUrl}/api1/zapret_na_redaktirovanie`,

        trata: `${baseUrl}/api1/trata`,

        renderWordDogovor: `${baseUrl}/api1/render_word_dogovor`,

        referenceTables: `${baseUrl}/api1/reference_tables`,

        kopirovanieDoma: `${baseUrl}/api1/kopirovanie_doma`,


        smetaGetParams: `${baseUrl}/api1/smeta_get_params`,
        pereminieGoogleSmetaApi: `${baseUrl}/api1/pereminie_google_smeta`,
        parametriGoogleSmetaApi: `${baseUrl}/api1/parametri_google_smeta_api`,
        konsruktiviGoogleSmetaApi: `${baseUrl}/api1/konsruktivi_google_smeta_api`,

        //пользователи CRM
        login: `${baseUrl}/api1/login`,

    },

    yesNoList: [
        {id: true, label: 'Да'},
        {id: false, label: 'Нет'},
    ],

    yesNoNullList: [
        {id: '', label: '-'},
        {id: true, label: 'Да'},
        {id: false, label: 'Нет'},
    ],


}