<template>

  <div class="py-3">


    <label class="small">Материал</label>
    <v-select
        class="mb-2 pt-0"
        label=""
            :readonly="data.zapretNaRedaktirovanie"
        value="Арболит"
        :items="['Брус', 'Арболит']"
    />

    <div class="row">
      <div class="col-12 mb-3 col-md-6">
        <label class="small">Объем фундамента, м3</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.foundationArea"
        />
      </div>

      <div class="col-12 mb-3 col-md-6">
        <label class="small">Объем коробки, м3</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.boxArea"
        />
      </div>

      <div class="col-12 mb-3 col-md-6">
        <label class="small">Площадь потолка и пола, м2</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.florAndCeilingArea"
        />
      </div>

      <div class="col-12 mb-3 col-md-6">
        <label class="small">Высота стен, м</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.wallHeight"
        />
      </div>
      <div class="col-12 mb-3 col-md-6">
        <label class="small">Перегородки, длина, м</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.lengthPartitions"
        />
      </div>
      <div class="col-12 mb-3 col-md-6">
        <label class="small">Мансарда</label>
        <v-select
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            value="Летняя"
            :items="['Летняя', 'Теплая', 'Нет']"
        />
      </div>
    </div>


    <h4 class="mb-3">Крыша</h4>
    <div class="row">
      <div class="col-12 mb-3 col-md-4">
        <label class="small">Площадь крыши, м2</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.roofArea"
        />
      </div>
      <div class="col-12 mb-3 col-md-4">
        <label class="small">Крыша - объем стропил, м3</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.roofRaftersVolume"
        />
      </div>
      <div class="col-12 mb-3 col-md-4">
        <label class="small">Крыша - объем обрешотки, м3</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.roofCrateVolume"
        />
      </div>
    </div>

    <h4 class="mb-3">Цоколь</h4>
    <div class="row">
      <div class="col-12 mb-3 col-md-6">
        <label class="small">Цоколь - сторона 1, м</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.plinthSide1"
        />
      </div>
      <div class="col-12 mb-3 col-md-6">
        <label class="small">Цоколь - сторона 2, м</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.plinthSide2"
        />
      </div>
    </div>

    <h4 class="mb-3">Отопление</h4>
    <label class="small">Учитывать стоимость отопления</label>
    <v-select
        class="mb-2 pt-0"
        label=""
        value="Да"
            :readonly="data.zapretNaRedaktirovanie"
        :items="['Да','Нет']"
    />

    <div class="row">
      <div class="col-12 mb-3 col-md-6">
        <label class="small">Радиаторы, кол-во секций</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.radiatorCount"
        />
      </div>

      <div class="col-12 mb-3 col-md-6">
        <label class="small">Теплый пол, площадь</label>
        <v-text-field
            class="mb-2 pt-0"
                :readonly="data.zapretNaRedaktirovanie"
            label=""
            v-model="data.warmFloorArea"
        />
      </div>
    </div>


    <h4 class="mb-3">Электричество</h4>

    <label class="small">Учитывать стоимость электричества</label>
    <v-select
        class="mb-5 pt-0"
        label=""
        value="Да"
            :readonly="data.zapretNaRedaktirovanie"
        :items="['Да','Нет']"
    />


    <h4 class="mb-3">ГВС и ХВС</h4>
    <label class="small">Учитывать стоимость ГВС и ХВС</label>
    <v-select
        class="mb-5 pt-0"
        label=""
        value="Да"
            :readonly="data.zapretNaRedaktirovanie"
        :items="['Да','Нет']"
    />

    <h4 class="mb-3">Канализация</h4>
    <label class="small">Учитывать стоимость канализации</label>
    <v-select
        class="mb-5 pt-0"
        label=""
            :readonly="data.zapretNaRedaktirovanie"
        value="Да"
        :items="['Да','Нет']"
    />

    <h4 class="mb-3">Окна</h4>
    <label class="small">Количество окон</label>
    <v-text-field
        class="mb-5 pt-0"
        label=""
            :readonly="data.zapretNaRedaktirovanie"
        v-model="data.windowCount"
    />

    <h4 class="mb-3">Двери и ворота</h4>
    <label class="small">Учитывать стоимость ворот</label>
    <v-select
        class="mb-2 pt-0"
        label=""
        value="Да"
            :readonly="data.zapretNaRedaktirovanie"
        :items="['Да','Нет']"
    />

    <div class="row">

      <div class="col-12 mb-3 col-md-4">
        <label class="small">Гараж и котельная, кол-во шт</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.garageAndBoilerCount"
        />
      </div>

      <div class="col-12 mb-3 col-md-4">
        <label class="small">Ворота Алютех, шт</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.garageAlutexCount"
        />
      </div>

      <div class="col-12 mb-3 col-md-4">
        <label class="small">Ворота распашные, шт</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.garageSwingDoorsCount"
        />
      </div>
    </div>

    <h4 class="mb-3">Забор</h4>

    <div class="row">

      <div class="col-12 col-md-6 mb-3">
        <label class="small">Забор лицевой (металл, профлист) - длина</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.frontFenceMetalArea"
        />
      </div>

      <div class="col-12 col-md-6 mb-3">
        <label class="small">Забор черновой (дерево) - длина</label>
        <v-text-field
            class="mb-2 pt-0"
            label=""
                :readonly="data.zapretNaRedaktirovanie"
            v-model="data.frontFenceWoodArea"
        />
      </div>
    </div>


    <button v-if="!data.zapretNaRedaktirovanie"  class="btn btn-warning shadow-sm br-20 px-5 " :disabled="isLoad" @click="saveData">
      Сохранить
    </button>
  </div>

</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {

  props: ['params', 'referenceTables'],

  data: () => ({
    isLoad: false,
    data: {},

  }),


  watch: {
    params() {
      this.data = {...this.params};
    }
  },

  created() {
    this.data = {...this.params};
  },


  methods: {


    async saveData() {
      this.isLoad = true

      try {
        let data = this.data;

        const url = `${LOCAL_CONFIG.urls.house}/${data.id}`;
        const response = await fetch(url, {
          method: 'post',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });
        if (response.status === 200 || response.status === 201) {
          this.$emit('showAlert', 'Данные сохранены');
          this.$emit('updateData',);
        } else if (response.status === 400) {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка. Проверьте пожалуйста что все поля заполнены'});
        } else {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка. подробности в консоли браузера'});
        }
      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

      this.isLoad = false;
    },

  },


}
</script>

<style scoped lang="scss">
@import 'src/assets/const';


</style>