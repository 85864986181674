<template>

  <div class="">

    <div>
      <v-simple-table
          v-if="historySet && historySet.length > 0"
          class="text-body-2 cursor-pointer"
      >
        <template v-slot:default>
          <thead class="">
          <tr>
            <th>Вкладка</th>
            <th>Тип операции</th>
            <th>Автор</th>
            <th>Дата</th>
            <th>Примечание</th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="item in historySet"
              @click="open(item)"
              :key="item.id">
            <td :class="item.color">
              {{ item.tab }}
            </td>
            <td :class="item.color">{{ item.typeOperation }}</td>
            <td>{{ item.user }}</td>
            <td>{{ item.date }}</td>
            <td>{{ item.note }}</td>


          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="w-100" v-else>
        <v-alert
            icon="$info"
            color="amber lighten-2"
            variant="tonal"
            :elevation="2"
            class="mb-3 w-100 small py-3 br-20"
        >Изменений пока нет
        </v-alert>
      </div>
    </div>


    <v-dialog

        v-model="dialogShow"

        width="500px"
    >
      <v-card  class="px-3 pt-4">
        <v-card-text>
          <div>
            <div><b>Вкладка: </b> {{ dialogNow.tab }}</div>
            <div><b>Операция: </b> {{ dialogNow.typeOperation }}</div>
            <div><b>Дата: </b> {{ dialogNow.date }}</div>
              <div :class="{'mb-5':dialogNow.changeValues}"><b>Автор: </b> {{ dialogNow.user }}</div>


          </div>
          <div class="pre" v-html="dialogNow.changeValues">
          </div>

           <v-btn plain class="br-20 mt-3" block @click="dialogShow = false">Закрыть</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>

export default {
  props: ['historySet', 'referenceTables'],

  data: () => ({
    isLoad: false,
    dialogNow: {},
    dialogShow: false,

  }),

  methods: {
    open(item) {
      this.dialogNow = item;
      this.dialogShow = true;
    }
  }


}
</script>

<style scoped lang="scss">
@import 'src/assets/const';

.prev, .next {
  position: absolute;
  top: 60px;
  font-size: 12pt;
  cursor: pointer;
  color: #fff;
  background: $color-primary;
  padding: 5px 3px 5px 3px;
  box-sizing: border-box;

  &:hover {
    background-color: $color-primary-hover;
  }
}

.prev {
  left: -19px;
  border-radius: 100% 0 0 100%;
}


.next {
  right: -19px;
  border-radius: 0 100% 100% 0;
}

.card-body {
  min-height: 10px;
}

</style>