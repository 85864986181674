<template>
  <div class="home-view h-100  ">



      <useful-services :user="user"  class=""/>



  </div>


</template>

<script>
import UsefulServices from "@/components/include/UsefulServices";


export default {
  name: 'HomeView',

  props: ['user'],

  components: {
    UsefulServices,
  },

  data() {
    return {}
  },

  async created() {
    this.$emit('loadComplete',);
  },


}
</script>

<style lang="scss" scoped>

</style>
