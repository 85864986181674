<template>
  <div class="my-3" v-if="[1,2,3,5].includes(user.role)">
    <div class="container ">
      <div class="row">
        <div class="col-12 mb-3">
          <h1 class="  mb-1" v-if="data.address"><b>{{ data.address }}</b></h1>
          <h1 class="  mb-1" v-else><b>Без адреса (№{{ data.id }})</b></h1>
        </div>

        <div class="col-12 mb-3 col-md-9">


          <v-progress-linear
              indeterminate
              v-if="isLoad"
              class="mt-3"
              color="orange"
          ></v-progress-linear>

          <div class="card shadow br-20 px-8 py-6">
            <v-tabs
                v-model="tab"
                color="orange"
            >
              <v-tab>Основная</v-tab>
              <v-tab v-if="[1,2,5].includes(user.role)">Характеристики</v-tab>
              <v-tab v-if="[1,2].includes(user.role)">Смета(Новая)</v-tab>
              <v-tab v-if="[1,2].includes(user.role)">Смета</v-tab>
              <v-tab v-if="[1,2,3].includes(user.role)">Бухгалтерия</v-tab>
              <v-tab v-if="[1,2,3].includes(user.role)">Файлы</v-tab>
              <v-tab v-if="[1,2,3, 5].includes(user.role)">История</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" class="py-4 ">
              <v-tab-item>
                <house-base-info-form
                    @showAlert="$emit('showAlert', $event)"
                    @updateData="getData"
                    :params="data"
                    :referenceTables="referenceTables"
                />
              </v-tab-item>

              <v-tab-item v-if="[1,2,5].includes(user.role)">
                <house-first-info-form
                    @showAlert="$emit('showAlert', $event)"
                    @updateData="getData"
                    :params="data"
                    :referenceTables="referenceTables"
                />
              </v-tab-item>


                          <v-tab-item v-if="[1,2].includes(user.role)">
                <house-smeta
                    @showAlert="$emit('showAlert', $event)"
                    @updateData="getData"
                    :params="data.estimate"
                    :zapretNaRedaktirovanie="data.zapretNaRedaktirovanie"
                    :houseId="data.id"
                    :referenceTables="referenceTables"
                />
              </v-tab-item>

              <v-tab-item v-if="[1,2].includes(user.role)">
                <house-estimate
                    @showAlert="$emit('showAlert', $event)"
                    @updateData="getData"
                    :params="data.estimate"
                    :zapretNaRedaktirovanie="data.zapretNaRedaktirovanie"
                    :houseId="data.id"
                    :referenceTables="referenceTables"
                />
              </v-tab-item>

              <v-tab-item v-if="[1,2,3].includes(user.role)">
                <house-buhgalteria
                    @showAlert="$emit('showAlert', $event)"
                    @updateData="getData"
                    :buhgalteria="data.buhgalteria"
                    :estimate="data.estimate"
                    :trati="data.trataSet"
                     :zapretNaRedaktirovanie="data.zapretNaRedaktirovanie"
                    :postuplenie="data.postuplenieSet"
                    :houseId="data.id"
                    :referenceTables="referenceTables"
                />
              </v-tab-item>

              <v-tab-item v-if="[1,2,3].includes(user.role)">
                <house-file-form
                    @showAlert="$emit('showAlert', $event)"
                    @updateData="getData"
                    :params="data"
                />
              </v-tab-item>

              <v-tab-item v-if="[1,2,3, 5].includes(user.role)">
                <house-history :historySet="data.historySet" :referenceTables="referenceTables"/>
              </v-tab-item>

            </v-tabs-items>

            <modal-kopirovania-doma ref="modalKopirovaniaDoma" @showAlert="$emit('showAlert', $event)"
                                    :house-id="data.id"/>
          </div>


        </div>

        <div class="col-12 mb-3 col-md-3">


          <div class="card shadow br-20 px-6 py-5 mb-5">
            <h5 class="mb-1">Панель управления</h5>
            <div>
              <v-switch color="primary" label="Архивность" class="mb-0" @click="sendInArchive()" v-model="data.isArchive"></v-switch>
            </div>

            <div>

               <v-switch @click="changeZapritNaRedaktirovanie()" readonly color="primary" label="Запрет редак."  class="mb-0"  v-model="data.zapretNaRedaktirovanie"></v-switch>
            </div>

            <hr>

            <!--            <button class="btn btn-warning px-6 br-20 w-100 mb-5  btn-sm" @click="getData()">Обновить</button>-->
            <a :href="`${urlRenderaDogovora}/${data.id}`" class="btn btn-warning px-6 br-20 w-100 mb-5 "
               target="_blank">Сформировать договор</a>
            <button class="btn btn-warning px-6 br-20 w-100 mb-5 ">Сформировать смету</button>
            <v-btn class="px-6 br-20 w-100 btn-sm  mb-5 " @click="$refs.modalKopirovaniaDoma.open()" color="warning">
              Скопировать
            </v-btn>

            <!--            <v-btn class="px-6 br-20 w-100 btn-sm " outlined @click="sendInArchive()" :color="inArchiveColor">-->
            <!--              {{ inArchiveText }}-->
            <!--            </v-btn>-->


          </div>

        </div>


      </div>


    </div>


  </div>
</template>

<script>


import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import HouseBaseInfoForm from "@/components/house/HouseBaseInfoForm";
import HouseFirstInfoForm from "@/components/house/HouseFirstInfoForm";
import HouseFileForm from "@/components/house/HouseFileForm";
import CookieHelper from "@/plugins/cookieHelper";
import HouseHistory from "@/components/house/HouseHistory";
import HouseEstimate from "@/components/house/HouseEstimate";
import HouseBuhgalteria from "@/components/house/HouseBuhgalteria";
import ModalKopirovaniaDoma from "@/components/include/ModalKopirovaniaDoma";
import HouseSmeta from "@/components/house/HouseSmeta";

export default {
  name: "HouseDv",
  props: ['user'],
  components: {
    HouseSmeta,
    ModalKopirovaniaDoma,
    HouseBuhgalteria, HouseEstimate, HouseHistory, HouseBaseInfoForm, HouseFirstInfoForm, HouseFileForm
  },
  data() {
    return {
      data: [],
      referenceTables: [],
      tab: 0,
      isLoad: false,
      urlRenderaDogovora: LOCAL_CONFIG.urls.renderWordDogovor,
    }
  },

  methods: {
    async getData() {
      this.isLoad = true

      try {
        const url = `${LOCAL_CONFIG.urls.house}/${this.$route.params.id}`;
        const response = await fetch(url, {method: 'get'});
        const responseData = await response.json();

        this.data = responseData.data;

      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

      this.isLoad = false;
    },

    async sendInArchive() {
      const note = prompt('Укажите примечание');
      if (note !== null) {
        this.isLoad = true

        try {
          const url = `${LOCAL_CONFIG.urls.house}/${this.data.id}`;
          const response = await fetch(url, {
            method: 'delete',
            body: JSON.stringify({
              note: note
            }),
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CookieHelper.getCookie('csrftoken')
            },
          });
          if (response.status === 200 || response.status === 201) {
            this.$emit('showAlert', 'Операция прошла успешна');
            await this.getData();
          } else {
            this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
          }
        } catch (e) {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
        }

        this.isLoad = false;
      }

    },

    async changeZapritNaRedaktirovanie() {

      if( !([1].includes(this.user.role))){
        this.$emit('showAlert', {'color': 'red', text: 'Для действия нехватает прав'});
        return false
      }
      const note = prompt('Укажите примечание');
      if (note !== null) {
        this.isLoad = true

        try {
          const url = `${LOCAL_CONFIG.urls.zapretNaRedaktirovanie}/${this.data.id}`;
          const response = await fetch(url, {
            method: 'post',
            body: JSON.stringify({
              note: note
            }),
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CookieHelper.getCookie('csrftoken')
            },
          });
          if (response.status === 200 || response.status === 201) {
            this.$emit('showAlert', 'Операция прошла успешна');
            await this.getData();
          } else {
            this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
          }
        } catch (e) {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
        }

        this.isLoad = false;
      }

    },

    async getReferenceTables() {
      const url = `${LOCAL_CONFIG.urls.referenceTables}`;
      try {
        const response = await fetch(url, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });
        this.referenceTables = await response.json();
      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу'});
      }
    },
  },

  async created() {
    await this.getReferenceTables()
    await this.getData()
    this.$emit('loadComplete',);
  },


  computed: {
    inArchiveText() {
      return this.data.isArchive ? "Вернуть из архива" : "В архив"
    },
    inArchiveColor() {
      return this.data.isArchive ? "blue" : "red"
    },
  },


}
</script>

<style scoped>

</style>