<template>
  <div class="">

    <div v-if="idNow">
      <a @click="close()" class="mb-4 d-block text-warning">
        <b>Назад</b>
      </a>
      <price-contract-form
          :idNow="idNow"
          @showAlert="$emit('showAlert', $event)"
      />
    </div>

    <div v-show="!idNow">
      <v-progress-linear
          indeterminate
          :active="isLoad"
          class="mt-3"
          color="orange"
      ></v-progress-linear>

      <v-simple-table>
        <template v-slot:default>
          <thead class="border ">
          <tr>
            <th class="text-left border bg-light">
              ID
            </th>
            <th class="text-left bg-light">
              Дата установки цены
            </th>
          </tr>
          </thead>
          <tbody class="border ">
          <tr
              v-for="item in data"
              :key="item.id"
          >
            <td class="border pointer-cursor" @click="open(item.id)">{{ item.id }}</td>
            <td class="pointer-cursor" @click="open(item.id)">{{ item.date }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

    </div>

  </div>
</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import PriceContractForm from "@/components/priceList/PriceContractForm";

export default {
  name: "PriceContractHistory",
  components: {PriceContractForm},
  data: () => ({
    data: [],
    isLoad: false,
    idNow: null,
  }),

  methods: {

    async getData() {
      this.isLoad = true

      try {
        const url = `${LOCAL_CONFIG.urls.priceContract}`;
        const response = await fetch(url, {method: 'get'});
        const responseData = await response.json();
        this.data = responseData.data;
      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

      this.isLoad = false;
    },

    async open(id) {
      this.idNow = id;
    },

    close() {
      this.idNow = null;
    },

  },

  created: async function () {
    await this.getData();
  },

}
</script>

<style scoped lang="scss">
@import 'src/assets/const';

.prev, .next {
  position: absolute;
  top: 60px;
  font-size: 12pt;
  cursor: pointer;
  color: #fff;
  background: $color-primary;
  padding: 5px 3px 5px 3px;
  box-sizing: border-box;

  &:hover {
    background-color: $color-primary-hover;
  }
}

.prev {
  left: -19px;
  border-radius: 100% 0 0 100%;
}


.next {
  right: -19px;
  border-radius: 0 100% 100% 0;
}

.card-body {
  min-height: 10px;
}

</style>