<template>
  <div class="">
    <v-progress-linear
        indeterminate
        :active="isLoad"
        class="mt-3"
        color="orange"
    ></v-progress-linear>


    <div class="">
      <div v-if="idNow">
        <h4 class="mb-5 "><b>Цена для договора #{{ data.id }} от {{ data.date }}</b></h4>
        <hr>
      </div>

      <div>
        <h4 class="mb-3">Фундамент, коробка, крыша</h4>

        <v-text-field
            :readonly="idNow"
            class="mb-2"
            label="Бетон М200 руб. за ..."
            type="number"
             step="any"
            v-model="data.concrete"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Брус, руб. за м3"
            v-model="data.balk"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Профлист, руб. за м3"
            v-model="data.profiledSheet"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Работы по постройке фундамента, руб. за м2"
            v-model="data.foundationWork"
        />

        <h4 class="mt-5 mb-3">Отопление</h4>

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Котёл, руб. за шт"
            v-model="data.boiler"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Дымовая труба, руб. за шт"
            v-model="data.chimney"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Электрокотёл, руб. за шт"
            v-model="data.electricBoiler"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Радиатор, руб. за секцию"
            v-model="data.radiator"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Теплый пол, руб. за м2"
            v-model="data.warmFloor"
        />

        <h4 class="mt-5 mb-3">Электрика</h4>

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Электрика, руб. за м2"
            v-model="data.electrics"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Стоимость работ по электрике, %"
            v-model="data.electricsWork"
        />

        <h4 class="mt-5 mb-3">ГВС и ХВС</h4>

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Скважина, руб. за шт."
            v-model="data.borehole"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Скважина, руб. за шт."
            v-model="data.depthPump"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Водонагреватель, руб. за шт."
            v-model="data.waterHeater"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Трубы и расходники, руб. за шт."
            v-model="data.pipes"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Стоимость работ по ГВС и ХВС, руб."
            v-model="data.gvsHvsWork"
        />

        <h4 class="mt-5 mb-3">Канализация</h4>

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Септик, руб. за шт."
            v-model="data.septic"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Стоимость работ по КНС, руб."
            v-model="data.knsWork"
        />

        <h4 class="mt-5 mb-3">Окна</h4>

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Окно, руб. за шт."
            v-model="data.window"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Стоимость работ по установки окон, руб. за шт."
            v-model="data.windowWork"
        />

        <h4 class="mt-5 mb-3">Двери и ворота</h4>

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Стоимость входных врат с установкой, руб. за шт."
            v-model="data.gatesEntrance"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Гараж и котельня, руб. за шт."
            v-model="data.garageAndBoiler"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Ворота Алютех, руб. за шт."
            v-model="data.garageAlutex"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Ворота Распашные, руб. за шт."
            v-model="data.garageSwingDoors"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Стоимость работ по установки 1 ворот"
            v-model="data.garageWork"
        />

        <h4 class="mt-5 mb-3">Прочее</h4>

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Логистика, %"
            v-model="data.logistics"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Непредвиденные расходы, %"
            v-model="data.unforeseenExpenses"
        />

        <v-text-field
              :readonly="idNow"
            class="mb-2"
            type="number"
               step="any"
            label="Прибавка к стоимости для отдаленных домов, %"
            v-model="data.remoteHouse"
        />


      </div>


      <button v-if="!idNow" class="btn btn-warning shadow-sm br-20 px-5 " :disabled="isLoad" @click="saveData">
        Сохранить
      </button>
    </div>

  </div>
</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  name: "PriceContractForm",


  data: () => ({
    data: {},
    isLoad: false,
  }),

  props: ['idNow'],

  methods: {

    async getData(idNow) {
      this.isLoad = true

      try {
        let url = ''
        if (idNow){
          url = `${LOCAL_CONFIG.urls.priceContract}/${idNow}`;
        }else {
          url = `${LOCAL_CONFIG.urls.priceContractLast}`;
        }
        const response = await fetch(url, {method: 'get'});
        const responseData = await response.json();
        this.data = responseData.data;
      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

      this.isLoad = false;
    },

    async saveData() {
      this.isLoad = true

      try {
        let data = this.data;
        delete data.date;

        const url = `${LOCAL_CONFIG.urls.priceContract}`;
        const response = await fetch(url, {
          method: 'post',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });
        if (response.status === 200 || response.status === 201) {
          this.$emit('showAlert', 'Данные сохранены');
        } else if (response.status === 400) {
          this.$emit('showAlert', 'Ошибка. Проверьте пожалуйста что все поля заполнены');
        } else {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
        }
      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

      this.isLoad = false;
    },

  },

  created: async function () {
    await this.getData(this.idNow);
  },

}
</script>

<style scoped lang="scss">
@import 'src/assets/const';

.prev, .next {
  position: absolute;
  top: 60px;
  font-size: 12pt;
  cursor: pointer;
  color: #fff;
  background: $color-primary;
  padding: 5px 3px 5px 3px;
  box-sizing: border-box;

  &:hover {
    background-color: $color-primary-hover;
  }
}

.prev {
  left: -19px;
  border-radius: 100% 0 0 100%;
}


.next {
  right: -19px;
  border-radius: 0 100% 100% 0;
}

.card-body {
  min-height: 10px;
}

</style>