<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          small
          class="ms-3"
          color="orange"
          v-bind="attrs"
          v-on="on"
      >
        mdi-help-circle
      </v-icon>
    </template>
    <span>{{text}}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "FormulaHelper",
  props:['text']
}
</script>

<style scoped>

</style>