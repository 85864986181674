<template>
  <div>
    <button class="btn btn-warning shadow-sm br-20 px-5 " @click="show()">
      Добавить поступление
    </button>

    <v-dialog
        v-model="dialogShow"
        width="700"
    >
      <v-card class="br-10 px-3 py-3" elevation="5">
        <form @submit.prevent="save()">

          <v-card-text>

            <div>
              <div class="h4 mb-5">Добавить поступление</div>
            </div>

            <!-- поля ввода -->
            <div class="row">
              <div class="col-12 col-lg-6">
                <div>
                  <label for="">Номер платежки*</label>
                  <v-text-field
                      requred
                      class="mb-3 pt-0"
                      label=""
                      v-model="data.nomerPlatezki"
                  />
                </div>
              </div>

              <div class="col-12 col-lg-6">
                <div>
                  <label for="">Дата платежки*</label>
                  <v-text-field
                      requred
                      type="date"
                      class="mb-3 pt-0"
                      label=""
                      v-model="data.date"
                  />
                </div>
              </div>

              <div class="col-12 col-lg-6">
                <div>
                  <label for="">Сумма по платежке*</label>
                  <v-text-field
                      requred
                      type="number"
                      step="any"
                      class="mb-3 pt-0"
                      label=""
                      v-model="data.sum"
                  />
                </div>
              </div>

              <div class="col-12 col-lg-6">
                <div>
                  <label for="" class="mb-1">Распределено всего:</label>
                  <v-chip outlined class=" shadow  px-5 w-100" :color="proverkaSumColor">
                    {{ sumPoDetalizazii.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }} из
                    {{ data.sum.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}
                  </v-chip>
                </div>
              </div>
            </div>


            <!-- таблица -->
            <div class="table-responsive mt-5">
              <table class="table align-middle   ">
                <tbody>
                <!-- шапка -->
                <tr class="font-weight-bold">
                  <td>Вид</td>
                  <td>Надо</td>
                  <td style="width: 250px;">Распределить</td>
                </tr>

                <tr v-for="(item, index) in vidiPostuplenia"
                    :key="index">
                  <td>{{ item }}</td>
                  <td>{{ buhgalteria.nado[index].toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</td>
                  <td>
                    <div class="d-flex">
                      <v-text-field
                          type="number"
                          step="any"
                          class=" pt-0"
                          label=""
                          v-model="data.postuplenie[index]"
                      />
                      <v-btn
                          @click="bistroZapolnitVid(index)"
                          color="warning"
                          plain
                          class="px-0 br-20 ml-3 btn-circle mt-1"
                      >
                        <v-icon dark>
                          mdi-flash
                        </v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>


          </v-card-text>

          <v-card-actions class="d-flex px-5">
            <div class="d-block ">
              <v-btn
                  plain

                  class="px-0 br-20"
                  @click="dialogShow=false"
              >
                Отмена
              </v-btn>
            </div>

            <div class="d-block ms-auto">
              <v-btn
                  type="submit"
                  color="warning"
                  class="px-5 br-20"
                  :loading="isLoad"
                  tile>
                Добавить
              </v-btn>
            </div>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  props: ['houseId', 'buhgalteria'],
  data() {
    return {
      isLoad: false,
      dialogShow: false,
      data: {
        sum: 0,
        postuplenie: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        }
      },
      vidiPostuplenia: {
        1: 'Фундамент',
        2: 'Коробка',
        3: 'Перекрытия',
        4: 'Армопояс',
        5: 'Стяжка',
        6: 'Крыша',
        7: 'Забор',
        8: 'Септик',
        9: 'Окна, двери, ворота',
        10: 'Электричество',
        11: 'Отопление, водоснабжение, канализация',
        12: 'Печь, камин',
      }
    }
  },

  methods: {
    show() {
      this.dialogShow = true;
    },

    bistroZapolnitVid(index) {
      this.data.postuplenie[index] = this.buhgalteria.nado[index];
    },


    close() {
      this.dialogShow = false;
      this.data = {
        sum: 0,
        postuplenie: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        },
        date: null,
        nomerPlatezki: null
      }
    },

    async save() {
      if (!this.data.nomerPlatezki || !this.data.date || !this.data.sum) {
        this.$emit('showAlert', {'color': 'red', text: 'Заполните все поля со звездочкой "*" '});
        return false
      }
      if (!this.proverkaSum) {
        this.$emit('showAlert', {'color': 'red', text: 'Суммы по платежки и видам не равны'});
        return false
      }

      const note = prompt('Укажите примечание');
      if (note !== null) {

        const url = `${LOCAL_CONFIG.urls.postuplenie}/${this.houseId}`;

        this.isLoad = true

        let data = this.data;
        data.note = note;

        try {
          const response = await fetch(url, {
            method: 'post',
            body: JSON.stringify(data),
            credentials: 'include',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CookieHelper.getCookie('csrftoken')
            },
          });

          //обработка если всё ок
          if (response.status === 200 || response.status === 201) {
            this.$emit('showAlert', 'Операция прошла успешна');
            this.$emit('updateData',)
            this.close()
          }
          //обработки если не всё ок
          else {
            this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
          }
        } catch (e) {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
        }

        this.isLoad = false;
      }
    },
  },

  computed: {
    sumPoDetalizazii() {
      let sum = 0;

      for (const key in this.data.postuplenie) {
        try {
          sum += Number(this.data.postuplenie[key])
        } catch {
          sum += 0;
        }

      }
      return sum
    },
    proverkaSum() {
      return Number(this.data.sum) === this.sumPoDetalizazii;
    },
    proverkaSumColor() {
      return this.proverkaSum ? 'success' : 'red';
    }
  }
}
</script>

<style scoped>

</style>