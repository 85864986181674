<template>

  <div class="py-3">

    <div>

      <label class="small">Адрес</label>
      <v-text-field
          class="mb-2 pt-0  "
          label=""
          :readonly="data.zapretNaRedaktirovanie"
          v-model="data.address"

      />


      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <label class="small">Статус</label>
          <v-select
              class="mb-2 pt-0"
              label=""
              item-text="name"
              item-value="id"
              :readonly="data.zapretNaRedaktirovanie"
              v-model="data.status"
              :items="referenceTables.statusList"
          />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <label class="small">Отдаленный дом</label>
          <v-select
              class="mb-2 pt-0"
              label=""
              :readonly="data.zapretNaRedaktirovanie"
              v-model="data.isRemoteHouse"
              item-text="label"
              item-value="id"
              :items="yesNoList"
          />
        </div>
      </div>


      <div class="row">
        <div class="col-12 col-md-12 mb-3">
          <label class="small">Клиент*</label>
          <v-text-field
              :readonly="data.zapretNaRedaktirovanie"
              class="mb-2 pt-0"
              label=""
              v-model="data.client"
          />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label class="small">Телефон клиента*</label>
          <v-text-field
              :readonly="data.zapretNaRedaktirovanie"
              class="mb-2 pt-0"
              label=""
              v-model="data.clientPhone"
          />
        </div>

        <div class="col-12 col-md-6 mb-3">
          <label class="small">Почта клиента</label>
          <v-text-field
              class="mb-2 pt-0"
              :readonly="data.zapretNaRedaktirovanie"
              label=""
              v-model="data.clientEmail"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <label class="small">Дата сделки</label>
          <v-text-field
              class="mb-2 pt-0"
              :readonly="data.zapretNaRedaktirovanie"
              label=""
              v-model="data.dateTrade"
              type="date"
          />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <label class="small">Срок сдачи</label>
          <v-text-field
              class="mb-2 pt-0"
              :readonly="data.zapretNaRedaktirovanie"
              label=""
              v-model="data.dateEndBuild"
              type="date"
          />
        </div>
      </div>

      <label class="small">Менеджер</label>
      <v-select
          class="mb-2 pt-0"
          label=""
          :readonly="data.zapretNaRedaktirovanie"
          item-text="name"
          item-value="id"
          v-model="data.manager"
          :items="referenceTables.managerList"
      />

      <label class="small">Примечание</label>
      <v-textarea
          rows="3"
          :readonly="data.zapretNaRedaktirovanie"
          class="mb-2 pt-0"
          label=""
          v-model="data.note"
      />


      <div class="row">

        <div class="col-12 col-md-4 mb-3">
          <label class="small">Цена по договору</label>
          <v-text-field
              class="mb-2 pt-0"
              readonly
              label=""

              v-model="data.priceContract"
          />
        </div>

        <div class="col-12 col-md-4 mb-3">
          <label class="small">Цена по смете</label>
          <v-text-field
              class="mb-2 pt-0"
              readonly
              label=""

              v-model="data.priceEstimate"
          />
        </div>

        <div class="col-12 col-md-4 mb-3">
          <label class="small">Цена по факту</label>
          <v-text-field
              class="mb-2 pt-0"
              readonly
              label=""

              v-model="data.priceFact"
          />
        </div>

      </div>

    </div>


    <div class="d-flex">
      <button v-if="!data.zapretNaRedaktirovanie" class="btn btn-warning shadow-sm br-20 px-5 " :disabled="isLoad"
              @click="saveData">
        Сохранить
      </button>

      <v-btn class="ms-auto br-20" color="primary" outlined>Смена цены</v-btn>
    </div>
  </div>

</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  name: "HouseBaseInfoForm",

  props: ['params', 'referenceTables'],

  data: () => ({
    isLoad: false,
    data: {},
    yesNoList: LOCAL_CONFIG.yesNoList,
    statusList: [
      'Строится',
      'Готов',
    ]
  }),


  watch: {
    params() {
      this.data = {...this.params};
    }
  },


  created() {
    this.data = {...this.params};
  },


  methods: {


    async saveData() {
      this.isLoad = true

      try {
        let data = this.data;
        delete data.date;

        const url = `${LOCAL_CONFIG.urls.house}/${data.id}`;
        const response = await fetch(url, {
          method: 'post',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });
        if (response.status === 200 || response.status === 201) {
          this.$emit('showAlert', 'Данные сохранены');
          this.$emit('updateData',);
        } else if (response.status === 400) {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка. Проверьте пожалуйста что все поля заполнены'});
        } else {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка. подробности в консоли браузера'});
        }
      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

      this.isLoad = false;
    },

  },


}
</script>

<style scoped lang="scss">
@import 'src/assets/const';


</style>