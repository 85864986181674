<template>
<div class="table-responsive">
          <table class="table table-bordered align-middle text-center small ">
            <tbody>
            <!-- шапка -->
            <tr class="font-weight-bold">
              <td>Вид работ</td>
              <td>Статус</td>
              <td>Стоимость по смете</td>
              <td>Поступило</td>
              <td>Потрачено</td>
              <td>Осталось</td>
            </tr>

            <tr>
              <td>Фундамент</td>
              <template>
                <td class="text-danger" v-if="estimateLocal.estimateFundamentPrice">Не достаточно средств</td>
                <td v-else>Оплатили полностью</td>
              </template>
              <td><span v-if="estimateLocal.estimateFundamentPrice">{{ estimateLocal.estimateFundamentPrice.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.sumOplatFundament">{{ buhgalteriaLocal.sumOplatFundament.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.sumTratFundament">{{ buhgalteriaLocal.sumTratFundament.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.ostatkiFundament">{{ buhgalteriaLocal.ostatkiFundament.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
            </tr>

            <tr>
              <td>Коробка</td>
              <template>
                <td class="text-danger" v-if="estimateLocal.estimateKorobkaPrice">Не достаточно средств</td>
                <td class="text-success" v-else>Оплатили полностью</td>
              </template>
              <td><span v-if="estimateLocal.estimateKorobkaPrice">{{ estimateLocal.estimateKorobkaPrice.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.sumOplatKorobka">{{ buhgalteriaLocal.sumOplatKorobka.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
                <td><span v-if="buhgalteriaLocal.sumTratKorobka">{{ buhgalteriaLocal.sumTratKorobka.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.ostatkiKorobka">{{ buhgalteriaLocal.ostatkiKorobka.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
            </tr>


            <tr>
              <td>Перекрытия</td>
              <template>
                <td class="text-danger" v-if="estimateLocal.estimatePerekritiePrice">Не достаточно средств</td>
                <td class="text-success" v-else>Оплатили полностью</td>
              </template>
              <td><span v-if="estimateLocal.estimatePerekritiePrice">{{ estimateLocal.estimatePerekritiePrice.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.sumOplatPerekritie">{{ buhgalteriaLocal.sumOplatPerekritie.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.sumTratPerekritie">{{ buhgalteriaLocal.sumTratPerekritie.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.ostatkiPerekritie">{{ buhgalteriaLocal.ostatkiPerekritie.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
            </tr>

            <tr>
              <td>Армопояс</td>
              <template>
                <td class="text-danger" v-if="estimateLocal.estimateArmpoiasPrice">Не достаточно средств</td>
                <td class="text-success" v-else>Оплатили полностью</td>
              </template>
              <td><span v-if="estimateLocal.estimateArmpoiasPrice">{{ estimateLocal.estimateArmpoiasPrice.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.sumOplatArmpoias">{{ buhgalteriaLocal.sumOplatArmpoias.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.sumTratArmpoias">{{ buhgalteriaLocal.sumTratArmpoias.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.ostatkiArmpoias">{{ buhgalteriaLocal.ostatkiArmpoias.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
            </tr>

            <tr>
              <td>Стяжка</td>
              <template>
                <td class="text-danger" v-if="estimateLocal.estimateStiazkaPrice">Не достаточно средств</td>
                <td class="text-success" v-else>Оплатили полностью</td>
              </template>
              <td><span v-if="estimateLocal.estimateStiazkaPrice">{{ estimateLocal.estimateStiazkaPrice.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.sumOplatStiazka">{{ buhgalteriaLocal.sumOplatStiazka.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
                            <td><span v-if="buhgalteriaLocal.sumTratStiazka">{{ buhgalteriaLocal.sumTratStiazka.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.ostatkiStiazka">{{ buhgalteriaLocal.ostatkiStiazka.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
            </tr>


            <tr>
              <td>Крыша</td>
              <template>
                <td class="text-danger" v-if="estimateLocal.estimateKrishaPrice">Не достаточно средств</td>
                <td class="text-success" v-else>Оплатили полностью</td>
              </template>
              <td><span v-if="estimateLocal.estimateKrishaPrice">{{ estimateLocal.estimateKrishaPrice.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.sumOplatKrisha">{{ buhgalteriaLocal.sumOplatKrisha.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
                <td><span v-if="buhgalteriaLocal.sumTratKrisha">{{ buhgalteriaLocal.sumTratKrisha.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.ostatkiKrisha">{{ buhgalteriaLocal.ostatkiKrisha.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
            </tr>

            <tr>
              <td>Забор</td>
              <template>
                <td class="text-danger" v-if="estimateLocal.estimateZaborPrice">Не достаточно средств</td>
                <td class="text-success" v-else>Оплатили полностью</td>
              </template>
              <td><span v-if="estimateLocal.estimateZaborPrice">{{ estimateLocal.estimateZaborPrice.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.sumOplatZabor">{{ buhgalteriaLocal.sumOplatZabor.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
                <td><span v-if="buhgalteriaLocal.sumTratZabor">{{ buhgalteriaLocal.sumTratZabor.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.ostatkiZabor">{{ buhgalteriaLocal.ostatkiZabor.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
            </tr>


            <tr>
              <td>Септик</td>
              <template>
                <td class="text-danger" v-if="estimateLocal.estimateSepticPrice">Не достаточно средств</td>
                <td class="text-success" v-else>Оплатили полностью</td>
              </template>
              <td><span v-if="estimateLocal.estimateSepticPrice">{{ estimateLocal.estimateSepticPrice.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.sumOplatSeptic">{{ buhgalteriaLocal.sumOplatSeptic.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.sumTratSeptic">{{ buhgalteriaLocal.sumTratSeptic.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.ostatkiSeptic">{{ buhgalteriaLocal.ostatkiSeptic.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
            </tr>


            <tr>
              <td>Окна, двери, ворота</td>
              <template>
                <td class="text-danger" v-if="estimateLocal.estimateOknaDveriVorotaPrice">Не достаточно средств</td>
                <td class="text-success" v-else>Оплатили полностью</td>
              </template>
              <td><span v-if="estimateLocal.estimateOknaDveriVorotaPrice">{{
                  estimateLocal.estimateOknaDveriVorotaPrice.toLocaleString('ru-RU', {'maximumFractionDigits': 2})
                }}</span>
              </td>
              <td>
                <span v-if="buhgalteriaLocal.sumOplatOknaDveriVorota">
                {{
                  buhgalteriaLocal.sumOplatOknaDveriVorota.toLocaleString('ru-RU', {'maximumFractionDigits': 2})
                }}</span>
              </td>
              <td><span v-if="buhgalteriaLocal.sumTratOknaDveriVorotac">{{ buhgalteriaLocal.sumTratOknaDveriVorota.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.ostatkiOknaDveriVorotac">{{ buhgalteriaLocal.ostatkiOknaDveriVorota.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
            </tr>


            <tr>
              <td>Электричество</td>
              <template>
                <td class="text-danger" v-if="estimateLocal.estimateElektrichestvoPrice">Не достаточно средств</td>
                <td class="text-success" v-else>Оплатили полностью</td>
              </template>
              <td>
                 <span v-if="estimateLocal.estimateElektrichestvoPrice">
                {{
                  estimateLocal.estimateElektrichestvoPrice.toLocaleString('ru-RU', {'maximumFractionDigits': 2})
                }}
                 </span>
              </td>
              <td>
                  <span v-if="buhgalteriaLocal.sumOplatElektrichestvo">
                {{
                  buhgalteriaLocal.sumOplatElektrichestvo.toLocaleString('ru-RU', {'maximumFractionDigits': 2})
                }}
                    </span>
              </td>
                 <td><span v-if="buhgalteriaLocal.sumTratElektrichestvo">{{ buhgalteriaLocal.sumTratElektrichestvo.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.ostatkiElektrichestvo">{{ buhgalteriaLocal.ostatkiElektrichestvo.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
            </tr>

            <tr>
              <td>Отопление, водоснабжение <br> канализация</td>
              <template>
                <td class="text-danger" v-if="estimateLocal.estimateOtoplenieVodaKnsPrice">Не достаточно средств</td>
                <td class="text-success" v-else>Оплатили полностью</td>
              </template>
              <td>
                 <span v-if="estimateLocal.estimateOtoplenieVodaKnsPrice">
                {{
                  estimateLocal.estimateOtoplenieVodaKnsPrice.toLocaleString('ru-RU', {'maximumFractionDigits': 2})
                }}
                 </span>
              </td>
              <td>
                 <span v-if="buhgalteriaLocal.sumOplatOtoplenieVodaKns">
                {{
                  buhgalteriaLocal.sumOplatOtoplenieVodaKns.toLocaleString('ru-RU', {'maximumFractionDigits': 2})
                }}
                 </span>
              </td>
              <td><span v-if="buhgalteriaLocal.sumTratVodaKns">{{ buhgalteriaLocal.sumTratVodaKns.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.ostatkiVodaKns">{{ buhgalteriaLocal.ostatkiVodaKns.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
            </tr>

            <tr>
              <td>Печь, камин</td>
              <template>
                <td class="text-danger" v-if="estimateLocal.estimatePechIKaminPrice">Не достаточно средств</td>
                <td class="text-success" v-else>Оплатили полностью</td>
              </template>
              <td><span v-if="estimateLocal.estimatePechIKaminPrice">{{ estimateLocal.estimatePechIKaminPrice.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.sumOplatPechIKamin">{{ buhgalteriaLocal.sumOplatPechIKamin.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
               <td><span v-if="buhgalteriaLocal.sumTratPechIKamin">{{ buhgalteriaLocal.sumTratPechIKamin.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.ostatkiPechIKamin">{{ buhgalteriaLocal.ostatkiPechIKamin.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
            </tr>


            <tr class="font-weight-bold ">
              <td colspan="2" class="text-right">ИТОГО:</td>
              <td class=""><span v-if="estimateLocal.priceEstimate">{{ estimateLocal.priceEstimate.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td class=""><span v-if="buhgalteriaLocal.sumOplatBuhgalteria">{{
                  buhgalteriaLocal.sumOplatBuhgalteria.toLocaleString('ru-RU', {'maximumFractionDigits': 2})
                }}</span>
              </td>
             <td><span v-if="buhgalteriaLocal.sumTratVsego">{{ buhgalteriaLocal.sumTratVsego.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
              <td><span v-if="buhgalteriaLocal.ostatkiVsego">{{ buhgalteriaLocal.ostatkiVsego.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
            </tr>


            </tbody>
          </table>
        </div>
</template>

<script>
export default {
  props:['estimateLocal', 'buhgalteriaLocal']
}
</script>

<style scoped>

</style>