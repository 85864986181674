<template>
  <div>
    <button class="btn btn-warning shadow-sm br-20 px-5 " @click="show()">
      Добавить трату
    </button>

    <v-dialog
        v-model="dialogShow"
        width="700"
    >
      <v-card class="br-10 px-3 py-3" elevation="5">
        <form @submit.prevent="save()">

          <v-card-text>

            <div>
              <div class="h4 mb-5">Добавить трату</div>
            </div>

            <!-- поля ввода -->
            <div class="row">

              <div class="col-12 col-lg-6">
                <div>
                  <label for="">Дата траты*</label>
                  <v-text-field
                      requred
                      type="date"
                      class="mb-3 pt-0"
                      label=""
                      v-model="data.date"
                  />
                </div>
              </div>

              <div class="col-12 col-lg-6">
                <div>
                  <label for="">Сумма трат*</label>
                  <v-text-field
                      requred
                      type="number"
                      step="any"
                      class="mb-3 pt-0"
                      label=""
                      v-model="data.sum"
                  />
                </div>
              </div>

              <div class="col-12 col-lg-12">
                <div>
                  <label for="">Вид работ*</label>
                  <v-select
                      requred
                      class="mb-3 pt-0"
                      label=""
                      item-text="name"
                      item-value="id"
                      :items="vidiTrat"
                      v-model="data.vidTrati"
                  />
                </div>
              </div>

                 <div class="col-12 col-lg-12">
                <div>
                  <label for="">Примечание</label>
                  <v-textarea
                      class="mb-3 pt-0"
                      label=""
                      v-model="data.primechanie"
                  />
                </div>
              </div>


            </div>


          </v-card-text>

          <v-card-actions class="d-flex px-5">
            <div class="d-block ">
              <v-btn
                  plain

                  class="px-0 br-20"
                  @click="dialogShow=false"
              >
                Отмена
              </v-btn>
            </div>

            <div class="d-block ms-auto">
              <v-btn
                  type="submit"
                  color="warning"
                  class="px-5 br-20"
                  :loading="isLoad"
                  tile>
                Добавить
              </v-btn>
            </div>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  props: ['houseId', ],
  data() {
    return {
      isLoad: false,
      dialogShow: false,
      data: {
        sum: 0,
        date: null,
        vidTrati: null,
        primechanie: null,
      },
      vidiTrat: [
        {id: 1, name: 'Фундамент'},
        {id: 2, name: 'Коробка'},
        {id: 3, name: 'Перекрытия'},
        {id: 4, name: 'Армопояс'},
        {id: 5, name: 'Стяжка'},
        {id: 6, name: 'Крыша'},
        {id: 7, name: 'Забор'},
        {id: 8, name: 'Септик'},
        {id: 9, name: 'Окна, двери, ворота'},
        {id: 10, name: 'Электричество'},
        {id: 11, name: 'Отопление, водоснабжение, канализация'},
        {id: 12, name: 'Печь, камин'},
      ]
    }
  },

  methods: {
    show() {
      this.dialogShow = true;
    },


    close() {
      this.dialogShow = false;
      this.data = {
        sum: 0,
        date: null,
        vidTrati: null,
        primechanie: null,
      }
    },

    async save() {
      if (!this.data.sum || !this.data.date || this.vidTrati) {
        this.$emit('showAlert', {'color': 'red', text: 'Заполните все поля со звездочкой "*" '});
        return false
      }

      const note = prompt('Укажите примечание');
      if (note !== null) {

        const url = `${LOCAL_CONFIG.urls.trata}/${this.houseId}`;

        this.isLoad = true

        let data = this.data;
        data.note = note;

        try {
          const response = await fetch(url, {
            method: 'post',
            body: JSON.stringify(data),
            credentials: 'include',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CookieHelper.getCookie('csrftoken')
            },
          });

          //обработка если всё ок
          if (response.status === 200 || response.status === 201) {
            this.$emit('showAlert', 'Операция прошла успешна');
            this.$emit('updateData',)
            this.close()
          }
          //обработки если не всё ок
          else {
            this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
          }
        } catch (e) {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
        }

        this.isLoad = false;
      }
    },
  },


}
</script>

<style scoped>

</style>