<template>
  <div class="table-responsive" v-if="trati.length">
    <table class="table table-bordered align-middle text-center small ">
      <tbody>
      <!-- шапка -->
      <tr class="font-weight-bold">
        <td>Вид работ</td>
        <td>Дата</td>
        <td>Сумма</td>
        <td>Примечание</td>
        <td>Удалить</td>
      </tr>

      <tr v-for="item in trati" :key="item.id">
        <td>{{ item.vidTratiLabel }}</td>
        <td>{{ item.date }}</td>
        <td><span v-if="item.sum">{{ item.sum.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }}</span></td>
        <td class="pre">{{ item.primechanie }}</td>
        <td class="pre">
          <v-btn
               v-if="!zapretNaRedaktirovanie"
              color="red"
              plain
              small
              class="px-5 br-20 btn-sm"
              :loading="isLoad"
              @click="deleteTratu(item.id)"
              tile>
            Удалить
          </v-btn>
        </td>
      </tr>


      </tbody>
    </table>
  </div>
</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  props: ['trati', 'zapretNaRedaktirovanie'],

    data() {
      return {
        isLoad: false
      }
    },

  methods: {
    async deleteTratu(id) {
      const note = prompt('Укажите примечание');
      if (note !== null) {


        this.isLoad = true;
        try {

          const url = `${LOCAL_CONFIG.urls.trata}/` + id;
          const response = await fetch(url, {
            method: 'delete',
            body: JSON.stringify({
              note: note
            }),
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CookieHelper.getCookie('csrftoken')
            },
          });
          if (response.status === 200 || response.status === 201) {
            this.$emit('showAlert', 'Трата удалена');
            this.$emit('updateData',);
          } else {
            this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
          }
        } catch (e) {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
        }

        this.isLoad = false;
      }
    }
  }
}
</script>

<style scoped>

</style>