<template>
  <div class="w-100 h-100 px-3 pt-2 pb-5  d-flex flex-column  bg--warning align-self-center justify-center">

    <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3 m-auto">
      <div class="card shadow-lg p-3 text-center br-20">
        <div class="text-center">
          <img class="logo mb-3" src="../../../backend/core/static/core/img/logo2.png" alt="">
        </div>
        <h3><b>БАСТИОН CRM</b></h3>

        <div>
          <div v-if="user.isAuth">

            <div class="my-5">
              <h3>Добро пожаловать, {{ user.username }}</h3>
            </div>



            <button class="btn btn-warning br-20 px-7 shadow mb-3" @click="logout()">
              Выйти
            </button>
            <div class="mt-3 text-warning">
              <a target="_blank" href="/admin/" class="text-warning">Админка</a>
            </div>
          </div>

          <div v-else class="card-body forma-vhoda text-center">


            <div class="mb-3">
              <v-text-field name="login" label="Логин" @keydown.enter="login"
                            v-model="userForm.login"></v-text-field>
            </div>

            <div class="mb-3">
              <v-text-field name="pas" type="password" label="Пароль" @keydown.enter="login"
                            v-model="userForm.pas"></v-text-field>
            </div>


            <button class="btn btn-warning br-20 px-5 shadow" @click="login()">
              Войти
            </button>


          </div>
        </div>


      </div>
    </div>


  </div>

</template>

<script>


import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  name: "LoginView",
  props: ['user'],
  components: {},
  data() {
    return {
      userForm: {
        login: "",
        pas: ""
      },
    }
  },

  methods: {
    async login() {

      try {
        const url = LOCAL_CONFIG.urls.login;
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(this.userForm),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });
        if (response.status === 200) {
          const responseJson = await response.json();
          CookieHelper.setCookie('sessionid', responseJson.sessionid, {'max-age': 31536000});
          window.location.href = "/";
        } else {
          this.$emit('showAlert', {'color': 'red', text: 'Ошибка авторизации'});
        }
      } catch (e) {
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }

    },

    async logout() {
      CookieHelper.deleteAllCookies()
      location.reload();
    }
  },

  async created() {
    this.$emit('loadComplete',);
  },


}
</script>

<style scoped lang="scss">

.tab-content {
  border: #dee2e6 1px solid;
}

.logo {
  width: 50px;
}

.logo-as {
  height: 70px;

}
</style>