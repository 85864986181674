<template>

  <v-expansion-panel class=" br-20 mb-5">
    <v-expansion-panel-header>
      <b class="h4 ">2. Параметры</b>


    </v-expansion-panel-header>


    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="12" v-for="(iGruppa, indexGruppa) in parametriDoma" :key="indexGruppa">
          <div v-if="parametriDoma[iGruppa.groupName] === iGruppa.groupVal">

            <h3 class="mb-6" v-if="iGruppa.otobrazat">{{ iGruppa.label }}</h3>

            <v-row>
              <template v-for="(item, index) in iGruppa.items" >
                <v-col cols="12" md="6" :key="index" v-if="item.otobrazat">

                  <div>
                    <label class="small" :class="{'text-success':item.googleRow}">{{ item.label }} ({{
                        item.izmerenie
                      }})</label>

                    <v-text-field
                        class="mb-2 pt-0"
                        type="number"
                        step="any"
                        label=""
                        @change="$forceUpdate()"
                        v-model="parametriIzCRM['row_'+item.indexRow]"


                    />
                  </div>

                </v-col>
              </template>
            </v-row>

          </div>
        </v-col>

        <v-col cols="12">
              <v-btn @click="calculate" color="primary" class="br-20">Далее</v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";
import CookieHelper from "@/plugins/cookieHelper";

export default {
  props: ['parametriDoma'],
  data: () => ({
    parametriIzCRM: {}
  }),


  async created() {
    const self = this;
    for (const [key, items] of Object.entries(this.parametriDoma)) {
      console.log(key)
      items.items.forEach((item) => {
        self.parametriIzCRM['row_' + item.indexRow] = item.poUmolchaniu
      })
    }
  },


  methods: {


    async calculate() {


      try {
        this.$emit('isLoad', true)
         this.$emit('changeSpisokStoimosteiKonstruktivov', null)
        this.$forceUpdate()
        const url = `${LOCAL_CONFIG.urls.parametriGoogleSmetaApi}`;
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(this.parametriIzCRM),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CookieHelper.getCookie('csrftoken')
          },
        });
        const result = await response.json();
        this.$emit('changeSpisokStoimosteiKonstruktivov', result)
         this.$emit('isLoad', false)

      } catch (e) {
         this.$emit('isLoad', false)
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }


    },


  },

}
</script>

<style scoped>

</style>