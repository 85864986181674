<template>
  <div>


    <div class="row">

      <div class="col-12 mb-1">
        <!--        <h5 class="my-3">Статус оплат</h5>-->

        <div class="d-flex mb-3">
          <v-chip class="me-3 shadow mb-3 text-white" color="blue" v-if="estimateLocal.priceEstimate">По смете:
            {{ estimateLocal.priceEstimate.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }} руб.
          </v-chip>

          <v-chip v-if="buhgalteriaLocal.sumOplatBuhgalteria" class="me-3 shadow mb-3 text-white" color="success">
            Оплатили:
            {{ buhgalteriaLocal.sumOplatBuhgalteria.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }} руб.
          </v-chip>

          <v-chip v-if=" buhgalteriaLocal.sumTratVsego" class="me-3 shadow mb-3 text-white "
                  color="red darken-3">Потратили:
            {{ buhgalteriaLocal.sumTratVsego.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }} руб.
          </v-chip>

          <v-chip v-if="  buhgalteriaLocal.ostatkiVsego" class="me-3 shadow mb-3 text-white" color="warning">
            Осталось:
            {{ buhgalteriaLocal.ostatkiVsego.toLocaleString('ru-RU', {'maximumFractionDigits': 2}) }} руб.
          </v-chip>
        </div>

        <table-buh :estimate-local="estimateLocal" :buhgalteria-local="buhgalteriaLocal"/>
      </div>

      <div class="col-12 mb-5">
        <h5 class="my-3">Поступление</h5>

        <table-postuplenie :postuplenie="postuplenie" :zapretNaRedaktirovanie="zapretNaRedaktirovanie"
         @showAlert="$emit('showAlert', $event)"
                    @updateData="$emit('updateData')"/>

        <modal-postuplenie v-if="!zapretNaRedaktirovanie" :buhgalteria="buhgalteria" :houseId="houseId" @showAlert="$emit('showAlert', $event)"
                           @updateData="$emit('updateData')"/>

      </div>


      <div class="col-12 mb-3">
        <h5 class="my-3">Траты</h5>

          <table-trati :trati="trati" :zapretNaRedaktirovanie="zapretNaRedaktirovanie"
           @showAlert="$emit('showAlert', $event)"
                    @updateData="$emit('updateData')"/>


        <modal-trat :houseId="houseId" v-if="!zapretNaRedaktirovanie"
                    @showAlert="$emit('showAlert', $event)"
                    @updateData="$emit('updateData')"/>
      </div>

    </div>


  </div>
</template>


<script>
import ModalPostuplenie from "@/components/include/ModalPostuplenie"
import ModalTrat from "@/components/include/ModalTrat"
import TablePostuplenie from "@/components/include/TablePostuplenie";
import TableBuh from "@/components/include/TableBuh";
import TableTrati from "@/components/include/TableTrati";

export default {
  components: {TableTrati, ModalTrat, TableBuh, TablePostuplenie, ModalPostuplenie},
  props: ['estimate', 'buhgalteria', 'referenceTables', 'houseId', 'postuplenie', 'trati', 'zapretNaRedaktirovanie'],


  data() {
    return {
      isLoad: false,
      estimateLocal: {},
      buhgalteriaLocal: {},
    }
  },


  watch: {
    estimate() {
      this.estimateLocal = {...this.estimate};
    },

    buhgalteria() {
      this.buhgalteriaLocal = {...this.buhgalteria};
    }
  },

  created() {
    this.estimateLocal = {...this.estimate};
    this.buhgalteriaLocal = {...this.buhgalteria};
  },

  computed: {},

}
</script>

<style scoped>

</style>