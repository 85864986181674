import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
     theme: {
    themes: {
      light: {
        primary: '#E6AF22',
        warning: '#E6AF22',
        orange: '#E6AF22',
      },
    },
  },
}

export default new Vuetify(opts)