<template>
  <div class="" v-if="[1,2,].includes(user.role)">

    <div class="my-3">
      <div class="container ">
        <div class="row">
          <div class="col-12 mb-3">
            <h1 class="text-uppercase  mb-1"><b>Расчет смет</b></h1>
          </div>

          <div class="col-12 " v-if="!isLoad && !alert">
            <v-expansion-panels>

              <estimate-panel :ref="'estimatePanelRef'" v-for="item in panels" :key="item.slug"
                              :params-data="vidiRabot[item.slug]"
                              :slug="item.slug"
                              @recalc="recalcSummi()"
                              :label="item.label"
              />


            </v-expansion-panels>

            <hr>
            <h4>Итоги</h4>
            <v-chip class="me-3 shadow" color="success">Итого: {{ sumVsego.toLocaleString('ru-RU',{'maximumFractionDigits':2}) }} руб.</v-chip>
            <v-chip class="me-3 shadow text-white" color="blue">Материалы: {{ sumMaterialov.toLocaleString('ru-RU',{'maximumFractionDigits':2}) }} руб.</v-chip>
            <v-chip class="me-3 shadow" color="warning">Работы: {{ sumRabot.toLocaleString('ru-RU',{'maximumFractionDigits':2}) }} руб.</v-chip>
          </div>

          <v-progress-linear
              indeterminate
              v-if="isLoad"
              class="mt-3"
              color="orange"
          ></v-progress-linear>
          <div class="col-12">
            <v-card class="text-center shadow" v-if="alert && !isLoad">
              <div class="py-5">
                <b>{{ alert }}</b>
              </div>
            </v-card>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>


import EstimatePanel from "@/components/estimate/EstimatePanel";

import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";

export default {
  name: "CalculationEstimatesView",
  components: {EstimatePanel},
  props:['user'],
  data() {
    return {
      isLoad: false,
      alert: '',
      vidiRabot: {},
      sumVsego: 0,
      sumRabot: 0,
      sumMaterialov: 0,
      panels: [
        {
          label: 'Погреб',
          slug: 'pogreb'
        },
        {
          label: 'Смотровая яма',
          slug: 'smotrovaiaIma',
        },
        {
          label: 'Свайный фундамент',
          slug: 'svainiFundament',
        },
        {
          label: 'Плитный фундамент',
          slug: 'plitniFundament',
        }
      ]
    }
  },

  methods: {
    async getData() {
      this.isLoad = true

      try {
        const url = `${LOCAL_CONFIG.urls.calculationEstimateGetParamsApi}`;
        const response = await fetch(url, {method: 'get'});
        const responseData = await response.json();

        this.vidiRabot = responseData;

      } catch (e) {
        this.alert = "Ошибка при обращение к серверу. Попробуйте обновить страницу";
        this.$emit('showAlert', {'color': 'red', text: 'Ошибка при обращение к серверу. Подробности в консоли'});
      }
      this.isLoad = false;

    },

    recalcSummi() {
      this.sumVsego = 0;
      this.sumMaterialov = 0;
      this.sumRabot = 0;
      const self = this;
      this.$refs.estimatePanelRef.forEach(function (item) {

        self.sumVsego += item.result.summi.summaVsego;
        self.sumMaterialov += item.result.summi.summaMaterialov;
        self.sumRabot += item.result.summi.summaRabot;
      });

    }
  },

  async created() {
    try {
      await this.getData()
    } catch (e) {
      //
    }

    this.$emit('loadComplete',);
  },


}
</script>

<style scoped lang="scss">

.tab-content {
  border: #dee2e6 1px solid;
}

.logo {
  width: 50px;
}

.logo-as {
  height: 70px;

}
</style>