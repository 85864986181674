<template>
  <div class="useful-services d-flex flex-1-1  bg-main h-100" >
    <video playsinline autoplay muted loop class="video-main">
      <source src="../../assets/video1.mp4" type="video/mp4">
    </video>

    <div class="bg-wrap d-flex flex-1-1 align-items-center h-100">
      <div class="container">
        <div class="row">

          <div class="col-12  mb-3">
            <h1 class="text-uppercase text-white text-center"><b>Бастион CRM</b></h1>
          </div>

          <div class="col-12">

            <div class="row">
              <template v-for="item in items" >
                <div :key="item.title" :class="item.className" v-if="item.prava.includes(user.role)">

                  <router-link class="text-decoration-none" :to="{name:item.link}">
                    <div class="card h-100 bg-light service br-20">
                      <div class="card-body br-20 service-body d-flex flex-column justify-space-between">
                        <div class="display-1 d-md-block"><b>{{ item.title }}</b></div>
                        <div class="d-flex flex-row justify-content-between ">

                          <div class="align-self-center d-none d-md-block">

                            <div class="btn-card shadow">
                              Перейти
                            </div>

                          </div>

                          <div class="img-wrap d-flex align-items-center ms-auto justify-content-center p-3">
                            <img :src="item.svg">
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </template>


            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LOCAL_CONFIG from "@/store/LOCAL_CONFIG";

export default {
  name: "UsefulSurvices",
  props: ['user'],
  data: () => ({
    items: [
      {
        title: 'Реестр домов',
        link: 'houseLv',
        className: 'col-12 col-md-4 mb-5 mb-md-0',
        svg: LOCAL_CONFIG.urls.imgUrl + 'house.svg',
        prava: [1, 2, 3, 5]
      },
      {
        title: 'Расчет смет',
        link: 'calculationEstimatesView',
        className: 'col-12 col-md-4 mb-5 mb-md-0',
        svg: LOCAL_CONFIG.urls.imgUrl + 'smeta.svg',
        prava: [1, 2,]
      },
      {
        title: 'Настройки цен',
        link: 'priceListLv',
        className: 'col-12 col-md-4 mb-5 mb-md-0',
        svg: LOCAL_CONFIG.urls.imgUrl + 'price_list.svg',
        prava: [1, 4,]
      },
    ],
  }),
}
</script>

<style scoped lang="scss">
@import "@/assets/const";

.service {
  border: #f4f5f6 1px solid !important;
}

.img-wrap {
  background: #fff;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  box-shadow: 0px 0px 16px -9px rgb(34 60 80 / 22%);

  svg {
    display: block;
  }
}

.card {
  color: #000 !important;
}

.service {
  min-height: 250px;
}


.btn-card {
  border-radius: 20px;
  background-color: #fff;
  padding: 10px 20px;
}

.service-body:hover {
  -webkit-transition: all 0.3s ease;;
  -moz-transition: all 0.3s ease;;
  -o-transition: all 0.3s ease;;
  transition: all 0.3s ease;
  //color: $color-warning;
  background-color: $color-warning-hover-card !important;
}

</style>